import { useMutation, useQuery } from '@tanstack/react-query';
import * as api from './profiles';

export const useGetProfileScores = (profileId: string, apiToken: string) =>
  useQuery({
    queryKey: ['profileScores', profileId],
    queryFn: async () => api.getProfileScores(profileId, apiToken),
  });

export const useGetMagicLinkEmail = (apiToken: string) =>
  useQuery({
    queryKey: ['apiToken', apiToken],
    queryFn: async () => api.getMagicLinkEmail(apiToken),
  });

export const useReissueMagicLink = () =>
  useMutation({
    mutationFn: ({
      apiToken,
      locale,
    }: {
      apiToken: string;
      locale: string;
    }) => {
      return api.reissueMagicLink(apiToken, locale);
    },
  });
