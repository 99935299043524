import React from 'react';
import { SvgComponentProps } from 'components/Svgs/types';

const SvgAlertIcon = ({ title, ...props }: SvgComponentProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    width="114"
    height="114"
    viewBox="0 0 114 114"
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <rect width={114} height={114} fill="#FCE5F0" rx={57} />
    <path
      fill="#B1105D"
      d="M84.558 54.172 60.978 30.59a4.92 4.92 0 0 0-6.957 0l-23.58 23.584a4.92 4.92 0 0 0 0 6.957l23.58 23.578a4.92 4.92 0 0 0 6.957 0l23.58-23.578a4.92 4.92 0 0 0 0-6.96ZM55.207 43.916a2.46 2.46 0 1 1 4.92 0v14.76a2.46 2.46 0 0 1-4.92 0v-14.76Zm2.585 28.314h-.069a3.761 3.761 0 0 1-3.744-3.616 3.628 3.628 0 0 1 3.562-3.764h.07a3.756 3.756 0 0 1 3.743 3.616 3.63 3.63 0 0 1-3.562 3.764Z"
    />
  </svg>
);

export default SvgAlertIcon;
