import { PROPOSITION_RANKS } from 'variables';

// Chip states
export const INVALID = 'INVALID';
export const VALID = 'VALID';
export const NEUTRAL = 'NEUTRAL';

export type ValidationChipError =
  | 'ONE_LEAST'
  | 'ONE_MOST'
  | 'TWO_UNIQUE_BETWEEN';

export interface ValidationChipErrorMap
  extends Partial<Record<ValidationChipError, boolean>> {}

export const ONE_LEAST = 'ONE_LEAST';
export const ONE_MOST = 'ONE_MOST';
export const TWO_UNIQUE_BETWEEN = 'TWO_UNIQUE_BETWEEN';
export const INCOMPLETE = 'INCOMPLETE';
export const ERROR_TYPES = [
  ONE_LEAST,
  ONE_MOST,
  TWO_UNIQUE_BETWEEN,
  INCOMPLETE,
];

const MIN_PROPOSITION_VALUE = '0';
const MAX_PROPOSITION_VALUE = (PROPOSITION_RANKS.length - 1).toString();
const EDGE_VALUES = [MIN_PROPOSITION_VALUE, MAX_PROPOSITION_VALUE];

export const getValuesFromFormData = (data: { [key: string]: string }) =>
  Object.values(data).filter((value) => value !== '');

const getMinPropositionValues = (values: string[]) =>
  values.filter((v) => v === MIN_PROPOSITION_VALUE);

const getMaxPropositionValues = (values: string[]) =>
  values.filter((v) => v === MAX_PROPOSITION_VALUE);

const getBetweenValues = (values: string[]) =>
  values.filter((v) => !EDGE_VALUES.includes(v));

type Values = string[];
export type Errors = { [key: string]: any };

export const getOneLChipState = (values: Values, errors: Errors) => {
  if (getMinPropositionValues(values).length === 1 && !errors[ONE_LEAST]) {
    return VALID;
  }

  return errors[ONE_LEAST] ? INVALID : NEUTRAL;
};

export const getOneMChipState = (values: Values, errors: Errors) => {
  if (getMaxPropositionValues(values).length === 1 && !errors[ONE_MOST]) {
    return VALID;
  }

  return errors[ONE_MOST] ? INVALID : NEUTRAL;
};

export const getTwoUniqueBetweenChipState = (
  values: Values,
  errors: Errors,
) => {
  if (getBetweenValues(values).length >= 2 && !errors[TWO_UNIQUE_BETWEEN]) {
    return VALID;
  }

  return errors[TWO_UNIQUE_BETWEEN] ? INVALID : NEUTRAL;
};

export const validate = (
  data: { [key: string]: string },
  validateAsIfComplete = false,
): string[] => {
  const values = getValuesFromFormData(data);
  const mins = getMinPropositionValues(values);
  const maxes = getMaxPropositionValues(values);
  const betweenValues = getBetweenValues(values);
  const uniqueBetweenValues = Array.from(new Set(betweenValues));

  const noOfInputs = Object.keys(data).length;
  const isComplete = values.length === noOfInputs || validateAsIfComplete;

  const currentErrors: string[] = [];

  if (isComplete) {
    if (mins.length !== 1) {
      currentErrors.push(ONE_LEAST);
    }

    if (maxes.length !== 1) {
      currentErrors.push(ONE_MOST);
    }

    if (uniqueBetweenValues.length < 2 || betweenValues.length < 2) {
      currentErrors.push(TWO_UNIQUE_BETWEEN);
    }
  } else {
    currentErrors.push(INCOMPLETE);
    if (mins.length > 1) {
      currentErrors.push(ONE_LEAST);
    }

    if (maxes.length > 1) {
      currentErrors.push(ONE_MOST);
    }

    if (betweenValues.length > 2) {
      currentErrors.push(TWO_UNIQUE_BETWEEN);
    }
    if (betweenValues.length > uniqueBetweenValues.length) {
      currentErrors.push(TWO_UNIQUE_BETWEEN);
    }
  }

  return currentErrors;
};

export const ERROR_ID_MAP: Record<ValidationChipError, string> = {
  [ONE_LEAST]: 'one_l',
  [ONE_MOST]: 'one_m',
  [TWO_UNIQUE_BETWEEN]: 'two_different',
};

export const getLabelledById = (errors: Record<string, unknown>) => {
  const firstError = Object.entries(errors).find(([key, value]) => {
    if (key === ONE_LEAST || key === ONE_MOST || key === TWO_UNIQUE_BETWEEN) {
      return !!value;
    }
    return false;
  })?.[0] as ValidationChipError;

  return firstError ? ERROR_ID_MAP[firstError] : undefined;
};
