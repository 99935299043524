import React, { useEffect } from 'react';
import { useGetProfileScores } from 'api/profile.hooks';
import { ProfileLayout, FullScreenError, FullScreenSpinner } from 'components';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import { RequestError } from 'api/RequestError';
import { Dialects } from 'types/dialects';
import { EVALUATOR_TITLE_KEYS, PAGE_TITLE_KEYS } from 'variables';
import ExploreReissue from './Explore/ExploreReissue';
import ExploreProfile from './Explore/ExploreProfile';

type UrlParametersDigitalProfile = {
  profileId: string;
  apiToken: string;
};

const DigitalProfile = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dialect = searchParams.get('dialect') as Dialects;

  const { profileId, apiToken } = useParams<UrlParametersDigitalProfile>();
  const {
    status,
    data: profile,
    error,
  } = useGetProfileScores(profileId!, apiToken!);
  const { t, i18n } = useTranslation();
  const { preferredProfileDialect } = profile || {};
  const { language: locale } = i18n;

  useEffect(() => {
    const changeLanguage = async (language: string) => {
      await i18n.changeLanguage(language);
      setSearchParams({ dialect: i18n.language });
    };

    const languageToChange = dialect || preferredProfileDialect;
    if (languageToChange) {
      changeLanguage(languageToChange);
    }
  }, [dialect, preferredProfileDialect, i18n, setSearchParams]);

  const requestError = error as RequestError;
  const isProfileTokenExpiredOrUsedError = requestError?.errorCodes?.includes(
    'INVITE_EXPIRED_OR_USED',
  );
  const isProfileNotFoundError =
    requestError?.errorCodes?.includes('NOT_FOUND');

  if (status === 'pending') {
    return <FullScreenSpinner />;
  }
  if (status === 'error' && isProfileNotFoundError) {
    return (
      <FullScreenError
        message={t('ui.io.explore.evaluator.digital_profile.error')}
      />
    );
  }
  if (status === 'error' && isProfileTokenExpiredOrUsedError) {
    return (
      <ProfileLayout title={t('ui.io.explore.evaluator.digital_profile.title')}>
        <Helmet
          title={`${t(PAGE_TITLE_KEYS.DIGITAL_PROFILE)} -
            ${t(EVALUATOR_TITLE_KEYS.EXPLORE)}`}
        />
        <ExploreReissue apiToken={apiToken} locale={locale} />
      </ProfileLayout>
    );
  }
  if (status === 'success') {
    return (
      <ProfileLayout title={t('ui.io.explore.evaluator.digital_profile.title')}>
        <Helmet
          title={`${t(PAGE_TITLE_KEYS.DIGITAL_PROFILE)} -
            ${t(EVALUATOR_TITLE_KEYS.EXPLORE)}`}
        />
        <ExploreProfile profile={profile} />
      </ProfileLayout>
    );
  }
  return (
    <FullScreenError
      message={t('ui.io.explore.evaluator.digital_profile.error')}
    />
  );
};

export default DigitalProfile;
