import React, { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm, UseFormSetError } from 'react-hook-form';
import { Box, Divider } from '@mui/material';
import { Evaluator, Proposition, PropositionRanks } from 'types/evaluator';
import { useTranslation } from 'react-i18next';
import { P } from '@insights-ltd/design-library/components';
import useAboveMobile from 'components/hooks/useAboveMobile';
import { ERROR_TYPES, validate } from './validationUtils';
import Propositions, {
  escapeAccessors,
  unescapeAccessors,
} from './Propositions';
import ValidationInstruction from './ValidationInstruction';
import ValidationChips from './ValidationChips';
import QuestionNavigation from './QuestionNavigation';

type FormDataType = { [key: string]: string };
type FrameProps = {
  propositions: Proposition[];
  submitAnswer: (newPropositionRanks: PropositionRanks) => void;
  propositionRanks: PropositionRanks;
  subjectFullName?: string;
  isFirstQuestion: boolean;
  isLastQuestion: boolean;
  onBack: () => void;
  evaluatorModel: Evaluator;
};

type ClearErrorsType = (name?: string | string[]) => void;

function setErrors(
  validationErrors: string[],
  setError: UseFormSetError<FormDataType>,
  clearErrors: ClearErrorsType,
) {
  ERROR_TYPES.forEach((error) => {
    if (validationErrors.includes(error)) {
      setError(error, { type: 'manual' });
    } else {
      clearErrors(error);
    }
  });
}

const DfcInfo = ({ subjectFullName }: { subjectFullName: string }) => {
  const { t } = useTranslation();
  return (
    <Box px={2} py={1} bgcolor="grey.200" my={2}>
      <P variant="body-bold">
        {t('ui.io.evaluator.dfc.subject-warning.answer', {
          subjectFullName,
        })}
      </P>
    </Box>
  );
};

const LeastMostLabels = () => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: 'flex',
        paddingTop: '1rem',
        paddingLeft: '1rem',
      }}
      data-testid="least-most-labels-box"
    >
      <Box sx={{ display: 'inherit' }}>
        <P variant="body-bold">
          {t('ui.io.evaluator.proposition.heading_l_short')}
        </P>
        <P>&nbsp;-&nbsp;</P>
        <P>{t('ui.io.evaluator.proposition.heading_l')}</P>
      </Box>
      <P>,&nbsp;</P>
      <Box sx={{ display: 'inherit' }}>
        <P variant="body-bold">
          {t('ui.io.evaluator.proposition.heading_m_short')}
        </P>
        <P>&nbsp;-&nbsp;</P>
        <P>{t('ui.io.evaluator.proposition.heading_m')}</P>
      </Box>
    </Box>
  );
};

const Frame = ({
  submitAnswer,
  propositions,
  propositionRanks,
  subjectFullName,
  isFirstQuestion,
  isLastQuestion,
  onBack,
  evaluatorModel,
}: FrameProps) => {
  const [nextButtonUsedThisFrame, setNextButtonUsedThisFrame] = useState(false);

  const defaultValues = propositions.reduce((acc, prop) => {
    return {
      ...acc,
      [escapeAccessors(prop.key)]: propositionRanks[prop.key]?.toString() || '',
    };
  }, Object.create({}));

  const methods = useForm<FormDataType>({
    mode: 'all',
    defaultValues,
    resolver: async (values) => {
      const validationErrors = validate(values, nextButtonUsedThisFrame);
      setErrors(validationErrors, methods.setError, methods.clearErrors);
      return {
        values,
        errors: {},
      };
    },
  });

  const {
    formState: { errors },
    getValues,
    handleSubmit,
    watch,
    trigger,
    setError,
    clearErrors,
  } = methods;
  useEffect(() => {
    trigger().then(); // trigger validation on first render
  }, [trigger]);
  watch(); // re-render on all changes

  const errorPillsContainer = useRef<HTMLDivElement>(null);
  const aboveMobile = useAboveMobile();

  const scrollToErrorPillsOnError = () => {
    if (!aboveMobile && errorPillsContainer.current) {
      errorPillsContainer.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };

  const onSubmit = (data: FormDataType) => {
    setNextButtonUsedThisFrame(true);
    const validationErrors = validate(data, true);

    if (!validationErrors.length) {
      const formattedData = Object.fromEntries(
        Object.entries(data).map((entry) => [
          [unescapeAccessors(entry[0])],
          parseInt(entry[1], 10),
        ]),
      );
      submitAnswer(formattedData);
    } else {
      setErrors(validationErrors, setError, clearErrors);
      scrollToErrorPillsOnError();
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ValidationInstruction
        errors={errors}
        subjectFullName={subjectFullName}
      />
      <div ref={errorPillsContainer}>
        <ValidationChips formData={getValues()} errors={errors} />
      </div>
      {evaluatorModel === 'DFC' && (
        <DfcInfo subjectFullName={subjectFullName!} />
      )}
      <Divider />
      <LeastMostLabels />
      <FormProvider {...methods}>
        <Propositions
          propositions={propositions}
          evaluatorModel={evaluatorModel}
          errors={errors}
        />
      </FormProvider>
      <Divider />
      <QuestionNavigation
        isFirstQuestion={isFirstQuestion}
        isLastQuestion={isLastQuestion}
        onBack={onBack}
      />
    </form>
  );
};
export default Frame;
