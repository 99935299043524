import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Answers,
  Evaluator,
  PropositionRanks,
  Question,
} from 'types/evaluator';
import { Typography } from '@mui/material';

import useAboveMobile from 'components/hooks/useAboveMobile';
import { Helmet } from 'react-helmet';
import { EVALUATOR_TITLE_KEYS, PAGE_TITLE_KEYS } from 'variables';
import ProgressIndicator from './ProgressIndicator';
import Frame from './Frame';
import Choice from './Choice';
import Open from './Open';

type Props = {
  questions: Question[];
  onEvaluatorSubmit: (answers: Answers) => void;
  subjectFullName?: string;
  evaluatorModel: Evaluator;
};

const useAnswers = () => {
  const [answers, setAnswers] = useState<Answers>({
    frames: {},
    choice: {},
    open: {},
  });

  const setPropositionRanks = (newPropositionRanks: PropositionRanks) =>
    setAnswers((old) => ({ ...old, frames: newPropositionRanks }));
  const setChoiceAnswer = (newChoiceAnswers: Record<string, string>) =>
    setAnswers((old) => ({ ...old, choice: newChoiceAnswers }));
  const setOpenAnswer = (newOpenAnswers: Record<string, string>) =>
    setAnswers((old) => ({ ...old, open: newOpenAnswers }));
  return {
    answers,
    setPropositionRanks,
    setChoiceAnswer,
    setOpenAnswer,
  };
};

const Questions = ({
  questions,
  onEvaluatorSubmit,
  subjectFullName,
  evaluatorModel,
}: Props) => {
  const { t } = useTranslation();
  const aboveMobile = useAboveMobile();
  const [questionIndex, setQuestionIndex] = useState<number>(0);
  const noOfQuestions = questions.length;
  const isLastQuestion = questionIndex === noOfQuestions - 1;
  const { answers, setPropositionRanks, setChoiceAnswer, setOpenAnswer } =
    useAnswers();

  const handleQuestionAdvance = (newState: Answers) => {
    if (isLastQuestion) {
      onEvaluatorSubmit(newState);
    } else {
      setQuestionIndex((oldQuestionIndex) => oldQuestionIndex + 1);
    }
  };

  const submitFrameAnswer = (newPropositionRanks: PropositionRanks) => {
    const newState = {
      ...answers,
      frames: { ...answers.frames, ...newPropositionRanks },
    };
    setPropositionRanks(newState.frames);
    handleQuestionAdvance(newState);
  };

  const submitChoiceAnswer = (key: string, value: string) => {
    const newState = {
      ...answers,
      choice: { ...answers.choice, [key]: value },
    };
    setChoiceAnswer(newState.choice);
    handleQuestionAdvance(newState);
  };

  const submitOpenAnswer = (key: string, value: string) => {
    const newState = {
      ...answers,
      open: { ...answers.open, [key]: value },
    };
    setOpenAnswer(newState.open);
    handleQuestionAdvance(newState);
  };

  const question = questions[questionIndex];
  const title = `${
    question.type === 'CHOICE' && evaluatorModel === 'DFC'
      ? t(PAGE_TITLE_KEYS.YOUR_RELATIONSHIP)
      : t(PAGE_TITLE_KEYS.QUESTION, {
          currentFrame: questionIndex + 1,
          total: questions.length,
        })
  }
          -
          ${t(EVALUATOR_TITLE_KEYS[evaluatorModel])}`;

  return (
    <>
      <Helmet title={title} />
      <Typography gutterBottom variant={aboveMobile ? 'h4' : 'h5'}>
        {t('ui.io.evaluator.progress_text', {
          currentFrame: questionIndex + 1,
          total: questions.length,
        })}
      </Typography>
      <ProgressIndicator
        currentQuestion={questionIndex}
        noOfQuestions={noOfQuestions}
      />
      {question.type === 'CHOICE' && (
        <Choice
          key={questionIndex}
          prompt={question.text.replaceAll('{SubjectName}', subjectFullName!)}
          subjectName={subjectFullName!}
          choices={question.answers}
          answer={answers.choice[question.key]}
          isFirstQuestion={questionIndex === 0}
          isLastQuestion={isLastQuestion}
          onBack={() => {
            setQuestionIndex((oldQuestionIndex) => oldQuestionIndex - 1);
          }}
          submitAnswer={(choice) => submitChoiceAnswer(question.key, choice)}
        />
      )}
      {question.type === 'FRAME' && (
        <Frame
          key={questionIndex}
          propositions={question.propositions}
          propositionRanks={answers.frames}
          subjectFullName={subjectFullName}
          isFirstQuestion={questionIndex === 0}
          isLastQuestion={isLastQuestion}
          onBack={() => {
            setQuestionIndex((oldQuestionIndex) => oldQuestionIndex - 1);
          }}
          submitAnswer={submitFrameAnswer}
          evaluatorModel={evaluatorModel}
        />
      )}
      {question.type === 'OPEN' && (
        <Open
          key={questionIndex}
          prompt={question.text}
          answer={answers.open[question.key]}
          subjectFullName={subjectFullName!}
          isFirstQuestion={questionIndex === 0}
          isLastQuestion={isLastQuestion}
          onBack={() => {
            setQuestionIndex((oldQuestionIndex) => oldQuestionIndex - 1);
          }}
          submitAnswer={(choice) => submitOpenAnswer(question.key, choice)}
        />
      )}
    </>
  );
};

export default Questions;
