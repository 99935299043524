import React from 'react';
import { LinearProgress } from '@mui/material';

type ProgressIndicatorProps = {
  currentQuestion: number;
  noOfQuestions: number;
};
const ProgressIndicator = ({
  currentQuestion,
  noOfQuestions,
}: ProgressIndicatorProps) => {
  const percentComplete = Math.round((currentQuestion / noOfQuestions) * 100);
  return (
    <LinearProgress
      variant="determinate"
      value={percentComplete}
      sx={{ height: '8px' }}
    />
  );
};

export default ProgressIndicator;
