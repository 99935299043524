import React from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material';
import { ButtonV2 as Button } from '@insights-ltd/design-library/components';

const ButtonContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '1rem',
});

type QuestionNavigationProps = {
  isFirstQuestion: boolean;
  isLastQuestion: boolean;
  onBack: () => void;
};

const QuestionNavigation = ({
  isFirstQuestion,
  isLastQuestion,
  onBack,
}: QuestionNavigationProps) => {
  const { t } = useTranslation();

  return (
    <ButtonContainer>
      <Button
        sx={{
          visibility: isFirstQuestion ? 'hidden' : 'visible',
        }}
        disabled={isFirstQuestion}
        onClick={onBack}
        variant="outlined"
        size="large"
        aria-hidden={isFirstQuestion}
        disableRipple
        buttonText={t('ui.io.navigation.back')}
      />

      <Button
        disableRipple
        type="submit"
        variant="contained"
        color="primary"
        size="large"
        buttonText={
          isLastQuestion
            ? t('ui.io.evaluator.finish')
            : t('ui.io.navigation.next')
        }
      />
    </ButtonContainer>
  );
};
export default QuestionNavigation;
