import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

const basePath =
  import.meta.env.BASE_URL === '/' ? '' : import.meta.env.BASE_URL;

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: 'en-GB',
    fallbackLng: 'en-GB',
    load: 'currentOnly',
    backend: {
      loadPath: `${basePath}/translations/{lng}.json`,
    },
    debug: import.meta.env.DEV,
    keySeparator: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
      prefix: '{',
      suffix: '}',
      defaultVariables: {
        'line-break': '<br />',
        'energy-symbol': '\u00b0',
      },
    },
    returnNull: false,
  });

if (import.meta.hot) {
  import.meta.hot.on('translations-update', () => {
    i18n.reloadResources().then(() => {
      i18n.changeLanguage(i18n.language);
    });
  });
}

export default i18n;
