import React from 'react';
import { SvgComponentProps } from './types';

const SvgMail = ({ title, ...props }: SvgComponentProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    width="72"
    height="72"
    viewBox="0 0 72 72"
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <path
      fill="inherit"
      d="M60 12H12c-3.3 0-5.97 2.7-5.97 6L6 54c0 3.3 2.7 6 6 6h48c3.3 0 6-2.7 6-6V18c0-3.3-2.7-6-6-6Zm0 12L36 39 12 24v-6l24 15 24-15v6Z"
    />
  </svg>
);

export default SvgMail;
