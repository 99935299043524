import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { Dialects } from 'types/dialects';
import { LOCALES } from 'variables';

const useApplyQueryDialect = () => {
  const { search } = useLocation();
  const { i18n } = useTranslation();

  const dialect: Dialects = new URLSearchParams(search).get(
    'dialect',
  ) as Dialects;

  useEffect(() => {
    if (
      dialect &&
      LOCALES.all.map((locale) => locale.tag).includes(dialect) &&
      i18n.language !== dialect
    ) {
      i18n.changeLanguage(dialect);
    }
  }, [dialect, i18n]);
};

export default useApplyQueryDialect;
