import { getBetaAvailable } from 'features';

const UNAUTHORIZED = 401;

const authenticatedFetch = async (
  path: string,
  requestInit?: RequestInit,
): Promise<Response> => {
  const betaAvailable = getBetaAvailable();
  let extraHeaders: HeadersInit | undefined;
  if (betaAvailable) {
    const beta = localStorage.getItem('beta') === 'true' || false;
    extraHeaders = beta ? { 'insights-beta': 'true' } : undefined;
  }

  const response = await fetch(import.meta.env.VITE_API_PREFIX + path, {
    ...requestInit,
    ...(extraHeaders && {
      headers: { ...requestInit?.headers, ...extraHeaders },
    }),
  });

  if (response.status === UNAUTHORIZED) {
    window.location.reload();
  }

  return response;
};

const unauthenticatedFetch = async (
  path: string,
  requestInit?: RequestInit,
): Promise<Response> => {
  const betaAvailable = getBetaAvailable();
  let extraHeaders: HeadersInit | undefined;
  if (betaAvailable) {
    const beta = localStorage.getItem('beta') === 'true' || false;
    extraHeaders = beta ? { 'insights-beta': 'true' } : undefined;
  }
  const response = await fetch(import.meta.env.VITE_API_PREFIX + path, {
    ...requestInit,
    ...(extraHeaders && {
      headers: { ...requestInit?.headers, ...extraHeaders },
    }),
  });

  if (response.status === UNAUTHORIZED) {
    window.location.reload();
  }

  return response;
};

export { authenticatedFetch, unauthenticatedFetch };
