import React from 'react';
import { SvgComponentProps } from './types';

const SvgColourCharts = ({ title, ...props }: SvgComponentProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    width="537"
    height="436"
    viewBox="0 0 537 436"
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <g filter="url(#colour-charts_svg__a)">
      <rect width={414} height={333} x={62} y={69} fill="#fff" rx={12} />
    </g>
    <circle cx={81.804} cy={81.601} r={5.4} fill="#FF605C" />
    <circle cx={99.792} cy={81.601} r={5.4} fill="#FFBD44" />
    <circle cx={117.797} cy={81.601} r={5.4} fill="#00CA4E" />
    <rect
      width={115.2}
      height={11.7}
      x={89.895}
      y={119.4}
      fill="#E7E7E7"
      rx={5.85}
    />
    <rect
      width={166.5}
      height={11.7}
      x={89.894}
      y={157.199}
      fill="#E7E7E7"
      rx={5.85}
    />
    <rect
      width={115.2}
      height={11.7}
      x={89.894}
      y={138.3}
      fill="#E7E7E7"
      rx={5.85}
    />
    <rect
      width={166.5}
      height={11.7}
      x={89.894}
      y={176.102}
      fill="#E7E7E7"
      rx={5.85}
    />
    <path
      fill="#BA3135"
      d="M423.788 184.651a56.244 56.244 0 0 1-38.868 53.497l-17.382-53.497h56.25Z"
    />
    <path
      fill="#FFC85F"
      d="M385.626 237.914a56.253 56.253 0 0 1-42.96-2.81l24.872-50.453 18.088 53.263Z"
    />
    <path
      fill="#8AA52D"
      d="M345.014 236.195a56.255 56.255 0 0 1-19.536-88.894l42.06 37.35-22.524 51.544Z"
    />
    <path
      fill="#0073B8"
      d="M325.474 147.306a56.247 56.247 0 0 1 62.492-15.065 56.25 56.25 0 0 1 35.814 53.382l-56.242-.972-42.064-37.345Z"
    />
    <circle cx={331.097} cy={255.299} r={3.6} fill="#0073B8" />
    <circle cx={331.088} cy={268.798} r={3.6} fill="#BA3135" />
    <circle cx={379.702} cy={255.298} r={3.6} fill="#8AA52D" />
    <circle cx={379.702} cy={268.798} r={3.6} fill="#FFC85F" />
    <rect
      width={28.8}
      height={5.4}
      x={336.481}
      y={252.598}
      fill="#E6E6E6"
      rx={2.7}
    />
    <rect
      width={28.8}
      height={5.4}
      x={336.481}
      y={266.1}
      fill="#E6E6E6"
      rx={2.7}
    />
    <rect
      width={28.8}
      height={5.4}
      x={385.095}
      y={252.598}
      fill="#E6E6E6"
      rx={2.7}
    />
    <rect
      width={28.8}
      height={5.4}
      x={385.095}
      y={266.1}
      fill="#E6E6E6"
      rx={2.7}
    />
    <path
      fill="#E6E6E6"
      d="M89.895 225.999a4 4 0 0 1 4-4h20.8a4 4 0 0 1 4 4v139.1h-28.8v-139.1Z"
    />
    <path
      fill="#0073B8"
      d="M89.894 276.4a4 4 0 0 1 4-4h20.8a4 4 0 0 1 4 4v88.7h-28.8v-88.7Z"
    />
    <path
      fill="#E6E6E6"
      d="M181.703 225.999a4 4 0 0 1 4-4h20.8a4 4 0 0 1 4 4v139.1h-28.8v-139.1Z"
    />
    <path
      fill="#BA3135"
      d="M181.703 311.497a4 4 0 0 1 4-4h20.8a4 4 0 0 1 4 4v53.6h-28.8v-53.6Z"
    />
    <path
      fill="#E6E6E6"
      d="M135.794 225.999a4 4 0 0 1 4-4h20.8a4 4 0 0 1 4 4v139.1h-28.8v-139.1Z"
    />
    <path
      fill="#8AA52D"
      d="M135.794 261.998a4 4 0 0 1 4-4h20.8a4 4 0 0 1 4 4v103.1h-28.8v-103.1Z"
    />
    <path
      fill="#E6E6E6"
      d="M227.612 225.999a4 4 0 0 1 4-4h20.8a4 4 0 0 1 4 4v139.1h-28.8v-139.1Z"
    />
    <path
      fill="#FFC85F"
      d="M227.612 284.499a4 4 0 0 1 4-4h20.8a4 4 0 0 1 4 4v80.6h-28.8v-80.6Z"
    />
    <g clipPath="url(#colour-charts_svg__b)" opacity={0.75}>
      <path
        fill="#fff"
        d="m365.773 12.706-20.941-4.07a2.675 2.675 0 0 0-3.127 2.108l-3.053 15.706a2.674 2.674 0 0 0 2.109 3.127l18.324 3.561 4.217 6.253 4.58-23.559a2.674 2.674 0 0 0-2.109-3.126Zm-5.671 15.197-15.706-3.053.509-2.617 15.706 3.052-.509 2.618Zm.764-3.926-15.706-3.053.508-2.618 15.706 3.053-.508 2.618Zm.763-3.927-15.706-3.053.509-2.617 15.706 3.052-.509 2.618Z"
      />
    </g>
    <g clipPath="url(#colour-charts_svg__c)" opacity={0.75}>
      <path
        fill="#fff"
        d="m497.416 47.98-5.111-2.222c.103-1.642-.796-3.254-2.385-3.945-1.59-.691-3.381-.249-4.512.946l-5.111-2.222a2.675 2.675 0 0 0-3.509 1.382l-7.443 17.119a2.674 2.674 0 0 0 1.382 3.509l17.119 7.443a2.675 2.675 0 0 0 3.509-1.382l7.443-17.119a2.674 2.674 0 0 0-1.382-3.509Zm-8.56-3.721c.673.292.984 1.082.692 1.754a1.338 1.338 0 0 1-1.755.691 1.338 1.338 0 0 1-.691-1.754 1.337 1.337 0 0 1 1.754-.691Zm-9.888 16.055-2.765-7.018 2.474-.974 1.795 4.531 11.562-4.554.969 2.486-14.035 5.529Z"
      />
    </g>
    <g clipPath="url(#colour-charts_svg__d)" opacity={0.75}>
      <path
        fill="#fff"
        d="m33.804 103.804-17.596-6.231a2.674 2.674 0 0 0-3.404 1.623l-6.23 17.596a2.674 2.674 0 0 0 1.623 3.404l17.596 6.231a2.674 2.674 0 0 0 3.404-1.623l6.23-17.596a2.674 2.674 0 0 0-1.623-3.404Zm-18.8 13.145-2.513-.89 3.115-8.798 2.514.89-3.116 8.798Zm5.028 1.78-2.514-.89 4.45-12.568 2.515.89-4.451 12.568Zm5.027 1.781-2.514-.89 1.78-5.028 2.515.89-1.78 5.028Z"
      />
    </g>
    <g clipPath="url(#colour-charts_svg__e)" opacity={0.75}>
      <path
        fill="#fff"
        d="m169.762 62.615 8.84-6.19 1.326 1.895-8.84 6.19-1.326-1.895Zm-2.211-3.157 8.84-6.19 1.326 1.895-8.84 6.19-1.326-1.895Zm4.421 6.314 8.84-6.19 1.327 1.895-8.84 6.19-1.327-1.895Zm.818-20.334-22.731 15.917a3.092 3.092 0 0 0-.757 4.294l11.495 16.418a3.092 3.092 0 0 0 4.294.757l22.732-15.917a3.092 3.092 0 0 0 .757-4.294l-11.495-16.417a3.093 3.093 0 0 0-4.295-.758Zm13.264 18.943-11.365 7.959-11.496-16.418 11.366-7.958 11.495 16.417Z"
      />
    </g>
    <path
      fill="#fff"
      d="M54 30v5h5l14.747-14.747-5-5L54 30Zm23.613-13.613c.52-.52.52-1.36 0-1.88l-3.12-3.12c-.52-.52-1.36-.52-1.88 0l-2.44 2.44 5 5 2.44-2.44Z"
      opacity={0.75}
    />
    <g clipPath="url(#colour-charts_svg__f)" opacity={0.75}>
      <path
        fill="#fff"
        d="m16.265 232.273-3.805 1.236-1.236-3.804-2.536.824 1.236 3.804-3.804 1.236.824 2.537 3.804-1.237 1.237 3.805 2.536-.824-1.236-3.805 3.804-1.236-.824-2.536Zm13.093-2.852a3.983 3.983 0 0 0 2.555-5.036 3.983 3.983 0 0 0-5.028-2.572 3.976 3.976 0 0 0-1.096.552 6.577 6.577 0 0 1 2.32 3.256c.44 1.357.41 2.727.037 3.997a3.974 3.974 0 0 0 1.212-.197Zm-6.34 2.06a3.983 3.983 0 0 0 2.555-5.036 3.983 3.983 0 0 0-5.028-2.572 3.994 3.994 0 0 0-2.568 5.04 3.995 3.995 0 0 0 5.04 2.568Zm9.284.012c1.353.583 2.434 1.536 2.92 3.032l.824 2.537 3.804-1.237-.824-2.536c-.634-1.953-4.031-2.181-6.724-1.796Zm-8.46 2.524c-2.537.824-7.197 3.741-6.373 6.277l.824 2.536 15.217-4.944-.824-2.537c-.824-2.536-6.309-2.156-8.845-1.332Z"
      />
    </g>
    <g
      fill="#fff"
      fillRule="evenodd"
      clipPath="url(#colour-charts_svg__g)"
      clipRule="evenodd"
      opacity={0.75}
    >
      <path d="m509.297 191.265 5.016-2.492a12.57 12.57 0 0 0-1.244-.488c-3.385-1.1-10.696-1.597-11.792 1.776l-.824 2.537 11.412 3.708-2.568-5.041Zm4.596-5.516a5.332 5.332 0 0 0 6.721-3.424 5.332 5.332 0 0 0-3.424-6.72 5.332 5.332 0 0 0-6.721 3.424 5.332 5.332 0 0 0 3.424 6.72Z" />
      <path d="m516.059 198.369-2.958-5.867 2.356-1.212 1.768 3.491 8.636-4.442 1.194 2.364-10.996 5.666Z" />
    </g>
    <g fill="#fff" opacity={0.75}>
      <path d="m503.848 239.739-2.771 4.284-10.643-6.907 2.771-4.284 10.643 6.907ZM413.339.003l4.415 2.554-6.339 10.995L407 11 413.339.003ZM417.902 54.18a.34.34 0 0 1-.293-.156c-.258-.362-.921-1.443-1.386-4.065-1.981-.38-2.782-1.041-3.075-1.248a.34.34 0 0 1-.138-.363c.052-.224-.128-.912 3.213-1.62.965-5.02 1.576-4.676 1.8-4.728h.069a.34.34 0 0 1 .293.155c.275.397.908 1.64 1.425 4.573 3.307.69 3.139 1.379 3.173 1.603a.337.337 0 0 1-.137.362c-.293.207-1.021.65-3.036 1.029-.896 4.468-1.598 4.388-1.839 4.44-.017 0-.052.017-.069.017ZM291.853 72.308 288 67.618l11.665-9.612 3.852 4.689-11.664 9.613ZM533.772 81.004l3.185 3.535-8.772 7.928L525 88.932l8.772-7.928ZM501.603 138.873a.57.57 0 0 1-.465-.259c-.396-.569-.947-2.812-1.688-6.883-3.066-.57-4.771-1.018-5.219-1.346a.586.586 0 0 1-.224-.552c.069-.345.155-.759 5.323-1.829 1.498-7.78 2.084-7.9 2.428-7.987.035 0 .069-.017.121-.017.172 0 .345.086.465.259.431.604 1.051 3.208 1.843 7.745 5.133 1.07 5.219 1.484 5.288 1.829.052.207-.034.414-.224.552-.448.328-2.17.776-5.271 1.346-1.377 6.917-1.894 7.038-2.256 7.107-.052.017-.086.035-.121.035ZM26.496 178.471l.002-6.074 15.1-.006-.002 6.075-15.1.005ZM21.979 62.003l1.758 4.424-10.979 4.376L11 66.38l10.979-4.377ZM139.969 13.704l-4.287 4.293L125 7.3l4.287-4.293 10.682 10.697ZM206.028 25.967l3.185 3.535-8.773 7.928-3.184-3.535 8.772-7.928ZM270.88 20.988c-.159 0-.305-.097-.415-.28-.367-.63-1.624-2.573-2.283-7.088-2.774-.644-3.57-1.7-3.973-2.064a.669.669 0 0 1-.195-.619c.073-.388-.279-1.35 4.388-2.55 1.357-8.656 2.307-8.29 2.625-8.375.036-.012.073-.012.097-.012.159 0 .306.097.416.28.391.667 1.219 3.056 1.94 8.106 4.643 1.202 4.437 2.163 4.511 2.563a.715.715 0 0 1-.196.62c-.403.363-1.265 1.407-4.075 2.05-1.246 7.709-2.412 7.284-2.742 7.381-.037-.012-.074-.012-.098-.012Z" />
    </g>
    <defs>
      <clipPath id="colour-charts_svg__b">
        <path
          fill="#fff"
          d="m340.105 5 31.412 6.106-6.106 31.412L334 36.412z"
        />
      </clipPath>
      <clipPath id="colour-charts_svg__c">
        <path
          fill="#fff"
          d="m475.778 34.21 29.346 12.76-12.76 29.346-29.346-12.76z"
        />
      </clipPath>
      <clipPath id="colour-charts_svg__d">
        <path
          fill="#fff"
          d="m11.259 91.577 30.164 10.682-10.681 30.164L.577 121.741z"
        />
      </clipPath>
      <clipPath id="colour-charts_svg__e">
        <path
          fill="#fff"
          d="m142.733 58.956 30.309-21.222 21.222 30.309-30.309 21.222z"
        />
      </clipPath>
      <clipPath id="colour-charts_svg__f">
        <path
          fill="#fff"
          d="M2 222.889 32.434 213l9.888 30.434-30.433 9.889z"
        />
      </clipPath>
      <clipPath id="colour-charts_svg__g">
        <path
          fill="#fff"
          d="m504.889 166 30.434 9.889-9.889 30.433L495 196.434z"
        />
      </clipPath>
      <filter
        id="colour-charts_svg__a"
        width={474}
        height={393}
        x={32}
        y={43}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={4} />
        <feGaussianBlur stdDeviation={15} />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
    </defs>
  </svg>
);

export default SvgColourCharts;
