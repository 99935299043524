import React from 'react';
import { Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import privacyPolicyURL from 'utils/privacyPolicy';

const PrivacyPolicyLink = () => {
  const { t, i18n } = useTranslation();
  return (
    <Link
      href={privacyPolicyURL(i18n.language)}
      target="_blank"
      rel="noopener"
      data-testid="privacy-notice-link"
    >
      {t('ui.io.footer.privacy_notice')}
    </Link>
  );
};

export default PrivacyPolicyLink;
