import React, { Suspense } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ErrorBoundary } from 'react-error-boundary';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import { datadogRum } from '@datadog/browser-rum';
import { BetaWidget } from '@insights-ltd/design-library/beta';
import { BetaProvider } from '@insights-ltd/design-library/components';
import { getMuiTheme } from '@insights-ltd/design-library/themes';
import { DATADOG_RUM_CONFIGS } from 'variables';
import { FullScreenSpinner } from 'components/FullScreen';
import { BETA_DEFAULT, getBetaAvailable } from './features';
import ApplicationRoutes, { ErrorRoute } from './Routes';

const theme = getMuiTheme();
const betaAvailable = getBetaAvailable();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

type ErrorReporter = (error: Error, info: React.ErrorInfo) => void;
// eslint-disable-next-line no-console
let errorReporter: ErrorReporter = (error, info) => console.error(error, info);

if (import.meta.env.PROD) {
  const hostConfig = DATADOG_RUM_CONFIGS.find(
    (config) => config.hostname === window.location.host,
  );

  if (hostConfig) {
    errorReporter = (error, info) => datadogRum.addError(error, info);
  }
}

const App = () => (
  <BetaProvider betaDefault={BETA_DEFAULT} enabled={getBetaAvailable()}>
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <Suspense fallback={<FullScreenSpinner />}>
          <ErrorBoundary FallbackComponent={ErrorRoute} onError={errorReporter}>
            {import.meta.env.MODE !== 'test' && betaAvailable && <BetaWidget />}
            <BrowserRouter basename={import.meta.env.BASE_URL}>
              <ApplicationRoutes />
            </BrowserRouter>
          </ErrorBoundary>
        </Suspense>
      </ThemeProvider>
    </QueryClientProvider>
  </BetaProvider>
);

export default App;
