import React from 'react';
import { styled } from '@mui/material';
import Footer from 'components/Footer/Default/DefaultFooter';
import useAboveMobile from 'components/hooks/useAboveMobile';

type DefaultLayoutProps = React.PropsWithChildren<{
  children: React.ReactNode | undefined;
}>;

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  marginTop: '2rem',
});

const InnerContent = styled('span')<{ aboveMobile: boolean }>(
  ({ aboveMobile }) => ({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: '1',
    alignItems: 'center',
    width: '100%',
    maxWidth: '1440px',
    marginRight: 'auto',
    marginLeft: 'auto',
    padding: '1rem',
    ...(aboveMobile && {
      paddingRight: '2rem',
      paddingLeft: '2rem',
    }),
  }),
);

const DefaultLayout: React.FunctionComponent<DefaultLayoutProps> = ({
  children,
}) => {
  const aboveMobile = useAboveMobile();
  return (
    <Container>
      <InnerContent aboveMobile={aboveMobile}>{children}</InnerContent>
      <Footer />
    </Container>
  );
};

export default DefaultLayout;
