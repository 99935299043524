import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import ColourChartSplitLayout from 'components/layout/ColourChartSplitLayout';
import { EVALUATOR_TITLE_KEYS, PAGE_TITLE_KEYS } from 'variables';

type FormLayoutDFCProps = {
  children?: React.ReactNode;
};

const FormLayoutDFC: React.FC<FormLayoutDFCProps> = ({ children }) => {
  const { t } = useTranslation();

  return (
    <ColourChartSplitLayout
      keepBanner
      title={t('ui.io.about_you.page_title')}
      model="DFC"
    >
      <Helmet
        title={`${t(PAGE_TITLE_KEYS.ABOUT_YOU)} - ${t(
          EVALUATOR_TITLE_KEYS.DFC,
        )}`}
      />
      {children}
    </ColourChartSplitLayout>
  );
};

export default FormLayoutDFC;
