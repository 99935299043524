const privacyPolicyURL = (langCode = 'en-GB') => {
  switch (langCode) {
    case 'da-DK':
      return 'https://www.insights.com/dk/danish-insights-online-privacy-notice/';
    case 'nl-NL':
      return 'https://www.insights.com/dutch-experience-portal-privacy-notice/';
    case 'fr-FR':
      return 'https://www.insights.com/fr/avis-de-confidentialite-d-insights-online/';
    case 'de-DE':
      return 'https://www.insights.com/de/insights-online-datenschutzerklarung/';
    case 'es-ES':
      return 'https://www.insights.com/es/spanish-insights-online-privacy-notice/';
    default:
      return 'https://www.insights.com/general-privacy-notice/';
  }
};
export default privacyPolicyURL;
