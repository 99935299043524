import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Menu, MenuItem, styled } from '@mui/material';
import LanguageIcon from '@mui/icons-material/Translate';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getTheme } from '@insights-ltd/design-library/themes';
import { MODEL_UI_SUPPORTED_LOCALE } from 'variables';
import { useLocation, useSearchParams } from 'react-router-dom';
import { Evaluator } from 'types/evaluator';
import { useGetEvaluatorLanguages } from '../../api/evaluator.hooks';

const StyledLanguageIcon = styled(LanguageIcon)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

const StyledSpan = styled('span')(({ theme }) => ({
  color: theme.palette.primary.main,
}));

const LanguageSelector = ({ model }: { model?: Evaluator }) => {
  const { t, i18n } = useTranslation();
  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const currentDialect = searchParams.get('dialect');
  const isExploreDigitalProfile =
    pathname.includes('/profile/') ||
    pathname.includes('exploredigitalProfile');
  const theme = getTheme();

  const languageSelectOption = isExploreDigitalProfile
    ? 'EXPLORE'
    : 'ALL_DIALECTS';

  const { data: supportedDialects } =
    useGetEvaluatorLanguages(languageSelectOption);

  useEffect(() => {
    document.documentElement.setAttribute('lang', i18n.language.split('-')[0]);
  }, [i18n.language]);

  const [languageMenu, setLanguageMenu] = useState<HTMLElement | null>(null);

  const handleLanguageMenuClose = async (language: string | null = null) => {
    if (language) {
      if (isExploreDigitalProfile && currentDialect !== language) {
        setSearchParams({ dialect: language });
      }
      await i18n.changeLanguage(language);
    }
    setLanguageMenu(null);
  };

  const selectLanguage = MODEL_UI_SUPPORTED_LOCALE(model).find(
    (locale) => locale.enabled && locale.tag === i18n.language,
  )?.text;

  const languageOptions = MODEL_UI_SUPPORTED_LOCALE(model);
  return (
    <>
      <Button
        aria-owns={languageMenu ? 'language-menu' : undefined}
        aria-haspopup="true"
        aria-label={t('ui.io.language.change-language')}
        onClick={(e) => setLanguageMenu(e.currentTarget)}
        data-testid="language-selector"
      >
        <StyledLanguageIcon />
        &nbsp;
        <StyledSpan>{selectLanguage}</StyledSpan>
        <ExpandMoreIcon fontSize="small" />
      </Button>
      <Menu
        id="language-menu"
        anchorEl={languageMenu}
        open={Boolean(languageMenu)}
        onClose={() => handleLanguageMenuClose()}
      >
        {isExploreDigitalProfile && supportedDialects
          ? supportedDialects.dialects.map((language) => (
              <MenuItem
                disableRipple
                key={language.value}
                selected={i18n.language === language.value}
                onClick={() => handleLanguageMenuClose(language.value)}
                sx={{
                  '&:hover': {
                    textDecoration: 'underline',
                  },
                }}
              >
                {language.textKey}
              </MenuItem>
            ))
          : languageOptions.map((language) => (
              <MenuItem
                disableRipple
                key={language.tag}
                selected={i18n.language === language.tag}
                onClick={() => handleLanguageMenuClose(language.tag)}
                sx={{
                  color: theme.brandColours.blue,
                  fontWeight: theme.typography.boldWeight,
                  '&.Mui-selected': {
                    backgroundColor: theme.brandColours.lightBlue,
                    color: theme.grey[800],
                  },
                  '&.Mui-focusVisible': {
                    textDecoration: 'underline',
                  },
                  '&:hover': {
                    textDecoration: 'underline',
                  },
                }}
              >
                {language.text}
              </MenuItem>
            ))}
      </Menu>
    </>
  );
};

export default LanguageSelector;
