import React, { useState, useMemo } from 'react';
import { JumpTo } from '@insights-ltd/design-library/components';
import { getTheme, spacingSizeMap } from '@insights-ltd/design-library/themes';
import { Box, Button, Tooltip, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { isoToLocaleString } from 'utils/dates';
import { ProfileScoreResponse } from 'api/profiles';
import DrawerHelpButton from 'components/DrawerHelpButton/DrawerHelpButton';
import FloatingHelpButton from 'components/FloatingHelpButton/FloatingHelpButton';
import useAboveMobile from 'components/hooks/useAboveMobile';
import SectionColourOrder from './SectionColourOrder';
import SectionIntro from './SectionIntro';
import SectionOverview from './SectionOverview';
import { ConnectMore } from './SectionConnectMore';
import SectionThemes from './SectionThemes';

const localTheme = getTheme();

const AllThemeTypes = [
  'learn',
  'remoteworking',
  'service',
  'wellbeing',
] as const;
export type ThemeType = (typeof AllThemeTypes)[number];

const StyledSection = styled('div')(({ theme }) => ({
  margin: `0 0 ${theme.spacing(5 * spacingSizeMap.XS)} 0`,
  [theme.breakpoints.up('md')]: {
    margin: `0 0 ${theme.spacing(2 * spacingSizeMap.L)} 0`,
  },
}));

type ProfileProps = {
  profile: ProfileScoreResponse;
};

const ExploreProfile = ({ profile }: ProfileProps) => {
  const { t, i18n } = useTranslation();
  const aboveMobile = useAboveMobile();
  const [tooltipText, setToolTipText] = useState(
    t('ui.io.explore.evaluator.digital_profile.tooltip.copy_link'),
  );
  const { language: locale } = i18n;

  useMemo(() => {
    setToolTipText(
      t('ui.io.explore.evaluator.digital_profile.tooltip.copy_link'),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);

  const learnerName = profile?.learnerName ? profile.learnerName : 'Unknown';
  const profileDate = profile?.profileDate
    ? isoToLocaleString(profile.profileDate, locale)
    : 'Unknown';
  const profileLink = window.location.href;

  return (
    <Box
      sx={{
        width: '100%',
      }}
    >
      {aboveMobile ? <DrawerHelpButton /> : <FloatingHelpButton />}
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
            padding: '0.1875rem 0 1.3125rem 0',
          },
        })}
      >
        <Box
          sx={(theme) => ({
            fontSize: theme.spacing(spacingSizeMap.M),
            fontWeight: 600,
            lineHeight: '30px',
            marginBottom: '8px',
            [theme.breakpoints.up('md')]: {
              fontSize: theme.spacing(spacingSizeMap.XL),
              lineHeight: '56px',
            },
          })}
        >
          <span className="data-hj-suppress">
            {learnerName} - {profileDate}
          </span>
        </Box>
        <Tooltip title={tooltipText} placement="top">
          <Button
            disableRipple
            onClick={() => {
              navigator.clipboard
                .writeText(profileLink)
                .then(() =>
                  setToolTipText(
                    t(
                      'ui.io.explore.evaluator.digital_profile.tooltip.link_copied',
                    ),
                  ),
                );
            }}
            sx={(theme) => ({
              height: theme.spacing(spacingSizeMap.XL),
              marginBottom: theme.spacing(spacingSizeMap.XS),
              marginTop: theme.spacing(spacingSizeMap.XS),
              [theme.breakpoints.down('md')]: {
                maxWidth: '100%',
              },
            })}
            variant="contained"
          >
            {t('ui.io.explore.evaluator.digital_profile.button.share')}
          </Button>
        </Tooltip>
      </Box>
      <JumpTo
        pageSections={[
          {
            label: t(
              'ui.io.explore.evaluator.digital_profile.jumpto_nav.introduction',
            ),
            anchorId: 'introduction',
          },
          {
            label: t(
              'ui.io.explore.evaluator.digital_profile.jumpto_nav.overview',
            ),
            anchorId: 'overview',
          },
          {
            label: t(
              'ui.io.explore.evaluator.digital_profile.jumpto_nav.colour_order',
            ),
            anchorId: 'colourOrder',
          },
          {
            label: t(
              'ui.io.explore.evaluator.digital_profile.jumpto_nav.connect_more',
            ),
            anchorId: 'connectMore',
          },
          {
            label: t(
              'ui.io.explore.evaluator.digital_profile.jumpto_nav.themes',
            ),
            anchorId: 'themes',
          },
        ]}
        title={t('ui.io.explore.evaluator.digital_profile.jumpto_nav.title')}
      />
      <StyledSection id="#introduction">
        <SectionIntro />
      </StyledSection>
      <StyledSection id="#overview">
        <SectionOverview profileConscious={profile.scores.conscious} />
      </StyledSection>
      <StyledSection id="#colourOrder">
        <SectionColourOrder profileConscious={profile.scores.conscious} />
      </StyledSection>
      <StyledSection id="#connectMore">
        <ConnectMore profileScores={profile.scores.opposite} />
      </StyledSection>
      <Box
        sx={(theme) => ({
          padding: `0 0 ${theme.spacing(2 * spacingSizeMap.L)} 0`,
          width: '100vw',
          height: '100%',
          [theme.breakpoints.up('xs')]: {
            paddingLeft: `${theme.spacing(spacingSizeMap.S)} !important`,
            paddingRight: `${theme.spacing(spacingSizeMap.S)} !important`,
          },
          position: 'relative',
          left: 'calc(-50vw + 50%)',
          backgroundColor: localTheme.grey[300],
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        })}
        id="#themes"
      >
        <SectionThemes />
      </Box>
    </Box>
  );
};

export default ExploreProfile;
