import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, styled, Typography } from '@mui/material';
import privacyPolicyURL from 'utils/privacyPolicy';
import Copyright from 'components/Copyright';

const DefaultFooter = () => {
  const { t, i18n } = useTranslation();

  const FooterWrapper = styled('footer')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '2rem 1rem',
    color: theme.palette.grey[100],
    backgroundColor: theme.palette.blue.main,
  }));

  return (
    <FooterWrapper>
      <Copyright color="inherit" />
      <Typography>
        <Link
          color="inherit"
          href={privacyPolicyURL(i18n.language)}
          target="_blank"
          rel="noopener"
        >
          {t('ui.io.footer.privacy_notice')}
        </Link>
      </Typography>
    </FooterWrapper>
  );
};

export default DefaultFooter;
