import React from 'react';
import {
  Box,
  Tabs,
  Tab,
  Typography,
  styled,
  TabScrollButton,
} from '@mui/material';
import useAboveMobile from 'components/hooks/useAboveMobile';
import { ColourProfileIcon, P } from '@insights-ltd/design-library/components';
import { colourMap, colourMapInverted } from 'variables';
import { Colour, ColourScoreWithPreference } from 'types/constants';
import { useTranslation } from 'react-i18next';

type ColourScoreWithPreferenceProps = {
  profileScores: ColourScoreWithPreference;
};

type TabPanelProps = {
  children?: React.ReactNode;
  index: number;
  value: number;
  sx: object;
};

type TabContentProps = {
  connect: string;
  connect_desc: string;
  do1: string;
  do2: string;
  dont1: string;
  dont2: string;
  value: string;
  value_desc: string;
  strength1: string;
  strength2: string;
  weakness1: string;
  weakness2: string;
};

type TabProps = {
  title: string;
  content: TabContentProps;
};

type TabGroupContentProps = Record<Colour, TabProps>;

type TabGroupProps = {
  title: string;
  content: TabGroupContentProps;
  profileScores: Colour[];
};

const MyTabScrollButton = styled(TabScrollButton)({
  '& .MuiSvgIcon-root': {
    fontSize: 'x-large',
  },
  overflow: 'hidden',
  transition: 'width 0.5s',
  marginBottom: '15px',
  backgroundColor: 'linear-gradient(to right bottom, #430089, #82ffa1)',
  width: '50',
  '&:first-of-type': {
    justifyContent: 'start',
    background: 'linear-gradient(to right, white 70%, transparent)',
    marginRight: '-40px',
    zIndex: 1,
  },
  '&:last-child': {
    justifyContent: 'end',
    background: 'linear-gradient(to right, transparent, white 30%)',
    marginLeft: '-40px',
  },
});

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, sx, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={sx}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const TabGroup = ({ title, content, profileScores }: TabGroupProps) => {
  const { t } = useTranslation();
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const aboveMobile = useAboveMobile();
  const tabIndicatorStyle = aboveMobile
    ? {
        left: 0,
        height: '40px',
        width: '4px',
        marginRight: '8px',
        backgroundColor: '#3C3C3C',
      }
    : { backgroundColor: '#3C3C3C' };

  const orientation = aboveMobile ? 'vertical' : 'horizontal';

  return (
    <Box
      sx={{
        flexGrow: 1,
        bgcolor: 'background.paper',
        display: 'flex',
        flexDirection: aboveMobile ? 'row' : 'column',
        width: '100%',
        mb: '40px',
      }}
    >
      <Box>
        <Box
          sx={(theme) => ({
            display: 'inline-block',
            fontSize: theme.breakpoints.up('md') ? '18px' : '16px',
            fontStyle: 'normal',
            fontWeight: '600',
            lineHeight: theme.breakpoints.up('md') ? '30px' : '24px',
            letterSpacing: '-0.36px',
            marginBottom: '20px',
          })}
        >
          <span>{t(`${title}`)}</span>
        </Box>
        <Tabs
          orientation={orientation}
          variant={!aboveMobile ? 'scrollable' : 'standard'}
          scrollButtons
          allowScrollButtonsMobile
          value={value}
          onChange={handleChange}
          aria-label={`${orientation} tabs`}
          sx={(theme) => ({
            padding: 0,
            minWidth: '218px',
            [theme.breakpoints.up('md')]: {
              marginRight: '192px',
            },
            [theme.breakpoints.down('md')]: {
              marginBottom: '20px',
            },
            '.Mui-selected': {
              color: `#3C3C3C !important`,
            },
          })}
          TabIndicatorProps={{
            style: tabIndicatorStyle,
          }}
          ScrollButtonComponent={MyTabScrollButton}
        >
          {profileScores.map((colour, index) => {
            const colourMapped = colourMapInverted[colour];

            return (
              <Tab
                key={colour}
                icon={
                  <Box style={{ marginLeft: '20px', marginRight: '12px' }}>
                    <ColourProfileIcon
                      colourEnergy={colourMapped}
                      whiteBackgroundBorder
                      iconSize={40}
                    />
                  </Box>
                }
                label={t(`${content[colour].title}`)}
                iconPosition="start"
                sx={(theme) => ({
                  padding: 0,
                  minHeight: '40px',
                  minWidth: theme.breakpoints.up('md') ? '218px' : '148px',
                  marginBottom: theme.breakpoints.up('md') ? '24px' : '16px',
                  justifyContent: 'start',
                  color: '#1673a3',
                })}
                id={`tab-${index}`}
                aria-controls={`${orientation}-tabpanel-${index}`}
              />
            );
          })}
        </Tabs>
      </Box>
      <Box>
        {profileScores.map((colour, index) => {
          return (
            <TabPanel
              value={value}
              index={index}
              key={colour}
              sx={{ display: 'flex', maxWidth: '720px' }}
            >
              <Box
                sx={(theme) => ({
                  display: 'block',
                  fontSize: theme.breakpoints.up('md') ? '24px' : '20px',
                  fontStyle: 'normal',
                  fontWeight: '600',
                  lineHeight: theme.breakpoints.up('md') ? '32px' : '28px',
                  letterSpacing: theme.breakpoints.up('md')
                    ? '-0.48px'
                    : '-0.2px',
                  marginBottom: '14px',
                })}
              >
                <span>
                  {t(
                    `ui.io.explore.evaluator.digital_profile.connect_more.${colour}_connect`,
                  )}
                </span>
              </Box>
              <P color="textSecondary">
                {t(`${content[colour].content.connect_desc}`)}
              </P>

              <h4 style={{ fontWeight: 600, marginBottom: '0px' }}>
                {t('ui.io.explore.evaluator.digital_profile.connect_more.do')}
              </h4>
              <ul style={{ marginTop: '0px', paddingLeft: '24px' }}>
                <li>
                  <P color="textSecondary">
                    {t(`${content[colour].content.do1}`)}
                  </P>
                </li>

                <li>
                  <P color="textSecondary">
                    {t(`${content[colour].content.do2}`)}
                  </P>
                </li>
              </ul>

              <h4 style={{ fontWeight: 600, marginBottom: '0px' }}>
                {t('ui.io.explore.evaluator.digital_profile.connect_more.dont')}
              </h4>
              <ul style={{ marginTop: '0px', paddingLeft: '24px' }}>
                <li>
                  <P color="textSecondary">
                    {t(`${content[colour].content.dont1}`)}
                  </P>
                </li>
                <li>
                  <P color="textSecondary">
                    {t(`${content[colour].content.dont2}`)}
                  </P>
                </li>
              </ul>
              <Box
                sx={(theme) => ({
                  display: 'block',
                  fontSize: theme.breakpoints.up('md') ? '24px' : '20px',
                  fontStyle: 'normal',
                  fontWeight: '600',
                  lineHeight: theme.breakpoints.up('md') ? '32px' : '28px',
                  letterSpacing: theme.breakpoints.up('md')
                    ? '-0.48px'
                    : '-0.2px',
                  marginBottom: '14px',
                })}
              >
                <span>
                  {t(
                    `ui.io.explore.evaluator.digital_profile.connect_more.${colour}_value`,
                  )}
                </span>
              </Box>
              <P color="textSecondary">
                <span>{t(`${content[colour].content.value_desc}`)}</span>
              </P>

              <h4 style={{ fontWeight: 600, marginBottom: '0px' }}>
                {t(
                  'ui.io.explore.evaluator.digital_profile.connect_more.strengths',
                )}
              </h4>
              <ul style={{ marginTop: '0px', paddingLeft: '24px' }}>
                <li>
                  <P color="textSecondary">
                    {t(`${content[colour].content.strength1}`)}
                  </P>
                </li>
                <li>
                  <P color="textSecondary">
                    {t(`${content[colour].content.strength2}`)}
                  </P>
                </li>
              </ul>
              <h4 style={{ fontWeight: 600, marginBottom: '0px' }}>
                {t(
                  'ui.io.explore.evaluator.digital_profile.connect_more.weaknesses',
                )}
              </h4>
              <ul style={{ marginTop: '0px', paddingLeft: '24px' }}>
                <li>
                  <P color="textSecondary">
                    {t(`${content[colour].content.weakness1}`)}
                  </P>
                </li>
                <li>
                  <P color="textSecondary">
                    {t(`${content[colour].content.weakness2}`)}
                  </P>
                </li>
              </ul>
            </TabPanel>
          );
        })}
      </Box>
    </Box>
  );
};

export const ConnectMore = ({
  profileScores,
}: ColourScoreWithPreferenceProps) => {
  const opossiteColourOrder: Colour[] = (
    Object.keys(profileScores).filter((key) => key !== 'preferred') as Colour[]
  ).sort((a, b) => profileScores[b] - profileScores[a]);

  const tabContent = (
    Object.values(colourMap) as Colour[]
  ).reduce<TabGroupContentProps>((prev, colour) => {
    const title = `ui.io.explore.evaluator.digital_profile.connect_more.${colour}_title`;
    const content = {
      connect: `ui.io.explore.evaluator.digital_profile.connect_more.${colour}_connect`,
      connect_desc: `ui.io.explore.evaluator.digital_profile.connect_more.${colour}_connect_desc`,
      do1: `ui.io.explore.evaluator.digital_profile.connect_more.${colour}_do1`,
      do2: `ui.io.explore.evaluator.digital_profile.connect_more.${colour}_do2`,
      dont1: `ui.io.explore.evaluator.digital_profile.connect_more.${colour}_dont1`,
      dont2: `ui.io.explore.evaluator.digital_profile.connect_more.${colour}_dont2`,
      value: `ui.io.explore.evaluator.digital_profile.connect_more.${colour}_value`,
      value_desc: `ui.io.explore.evaluator.digital_profile.connect_more.${colour}_value_desc`,
      strength1: `ui.io.explore.evaluator.digital_profile.connect_more.${colour}_strength1`,
      strength2: `ui.io.explore.evaluator.digital_profile.connect_more.${colour}_strength2`,
      weakness1: `ui.io.explore.evaluator.digital_profile.connect_more.${colour}_weakness1`,
      weakness2: `ui.io.explore.evaluator.digital_profile.connect_more.${colour}_weakness2`,
    };

    return {
      ...prev,
      [colour]: { title, content },
    };
  }, {} as TabGroupContentProps);

  return (
    <Box>
      <TabGroup
        title="ui.io.explore.evaluator.digital_profile.connect_more.section_heading"
        content={tabContent}
        profileScores={opossiteColourOrder}
      />
    </Box>
  );
};
