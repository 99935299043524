import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { Box, Typography } from '@mui/material';
import { SubtextTextField } from 'components/SubtextTextField';
import { Evaluator } from 'types/evaluator';
import { setFocusOnFirstError } from 'utils/formHelpers';
import FormLayout from './FormLayout';
import FormNavigation from './FormNavigation';
import { Step1Data } from './types';

const Step1 = ({
  onNext,
  stepNumber,
  stepMax,
  userInfo,
  model,
}: {
  onNext: (data: Step1Data) => void;
  stepNumber: number;
  stepMax: number;
  userInfo: Step1Data;
  model: Evaluator;
}) => {
  const { t } = useTranslation();
  const methods = useForm<Step1Data>({
    defaultValues: userInfo,
  });

  const textFields = [
    {
      id: 'full-name',
      name: 'fullName',
      label: t('ui.io.user_info.full_name.input_description'),
      placeholder: t('ui.io.user_info.non_sign_up.full_name.input_placeholder'),
      errorMessage: t('ui.io.user_info.error.name_required'),
      optional: false,
      dataTestId: 'full-name-field',
      autocomplete: 'name',
    },
    {
      id: 'known-as',
      name: 'knownAs',
      label: t('ui.io.user_info.known_as.input_description'),
      placeholder: t('ui.io.user_info.non_sign_up.known_as.input_placeholder'),
      subtext: t('ui.io.user_info.known_as.input_tooltip'),
      errorMessage: t('ui.io.user_info.error.known_as_required'),
      optional: false,
      dataTestId: 'known-as-field',
      autocomplete: 'known-as',
    },
    {
      id: 'referral-code',
      name: 'referralCode',
      label: t('ui.io.user_info.non_sign_up.referral-code.input-description'),
      placeholder: t(
        'ui.io.user_info.non_sign_up.referral-code.input-placeholder',
      ),
      optional: true,
      dataTestId: 'referral-code-field',
      autocomplete: 'referral-code',
    },
  ];

  return (
    <FormLayout
      step={stepNumber}
      stepMax={stepMax}
      subtitle={t('ui.io.about_you.step_one.subtitle')}
      model={model}
    >
      <form
        onSubmit={methods.handleSubmit(onNext, (errors) => {
          setFocusOnFirstError(errors, methods.setFocus);
        })}
        noValidate
      >
        <Box mb={2}>
          <Typography color="textSecondary">
            {t('ui.io.onboarding.finish_creating_account')}
          </Typography>
        </Box>
        <div>
          <FormProvider {...methods}>
            {textFields.map((field) => (
              <SubtextTextField
                key={field.id}
                id={field.id}
                name={field.name}
                label={field.label}
                placeholder={field.placeholder}
                subtext={field.subtext || ''}
                register={methods.register}
                errorMessage={field.errorMessage}
                optional={field.optional}
                dataTestId={field.dataTestId}
              />
            ))}
          </FormProvider>
        </div>
        <FormNavigation />
      </form>
    </FormLayout>
  );
};

export default Step1;
