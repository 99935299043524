import { authenticatedFetch } from 'api/fetch';
import { ColourScoreWithPreference } from 'types/constants';
import { RequestError, RequestErrorBody } from './RequestError';

export type ProfileScoreResponse = {
  learnerName: string;
  knownAs: string;
  profileDate: string;
  preferredProfileDialect: string;
  scores: {
    conscious: ColourScoreWithPreference;
    lessConscious: ColourScoreWithPreference;
    opposite: ColourScoreWithPreference;
  };
};

export const getProfileScores = async (
  profileId: string,
  apiToken: string,
): Promise<ProfileScoreResponse> => {
  if (!profileId) {
    throw Error('Error getting Profile ID');
  }
  if (!apiToken) {
    throw Error('Error getting Api Token');
  }

  const response = await authenticatedFetch(
    `/api/v1/explore-profiles/${profileId}?token=${apiToken}`,
  );

  if (!response.ok) {
    const err: RequestErrorBody = await response.json();
    throw new RequestError(response.status.toString(), err);
  }
  return response.json();
};

export const getMagicLinkEmail = async (apiToken: string) => {
  const response = await authenticatedFetch(
    `/api/v1/profile-link-reissue/email/${apiToken}`,
  );

  if (!response.ok) {
    throw Error(response.status.toString());
  }
  return response.json();
};

export const reissueMagicLink = async (apiToken: string, locale: string) => {
  const response = await authenticatedFetch(
    '/api/v1/profile-link-reissue/renew',
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ dialect: locale, token: apiToken }),
    },
  );

  if (!response.ok) {
    throw Error(response.status.toString());
  }
};
