import { Evaluator, Question } from 'types/evaluator';
import { useQuery, useMutation, UseQueryOptions } from '@tanstack/react-query';
import { EvaluatorsResponse, Languages } from './evaluator';
import * as api from './evaluator';

type QueryOptionsConfig<T> = Omit<UseQueryOptions<T>, 'queryKey' | 'queryFn'>;

export const useGetEvents = () => {
  return useQuery({
    queryKey: ['events'],
    queryFn: api.getEvents,
  });
};

export const useGetEvaluators = (
  eventId: string,
  config: QueryOptionsConfig<EvaluatorsResponse> = {},
) =>
  useQuery({
    queryKey: ['evaluators', { eventId }],
    queryFn: () => api.getEvaluators(eventId),
    ...config,
  });

export type EvaluatorDetails = {
  seed: number;
  dialect: string;
  pronoun: string;
  questions: Question[];
};

export const useGetEvaluatorLanguages = (
  languageSelectOption?: string,
  dialect?: string,
) =>
  useQuery<Languages>({
    queryKey: ['languages', languageSelectOption, dialect],
    queryFn: () => api.getEvaluatorLanguages(languageSelectOption, dialect),
  });

export const useGetEvaluator = (model: Evaluator, dialect: string) =>
  useQuery<EvaluatorDetails>({
    queryKey: ['evaluator', { dialect }],
    queryFn: async () => {
      if (model === 'DFC') {
        const { before, after, frames, ...rest } = await api.getEvaluator(
          model,
          dialect,
        );
        return {
          ...rest,
          questions: [
            ...before.map((b) => ({
              ...b,
              type: 'CHOICE' as const,
            })),
            ...frames.map((frame) => ({
              ...frame,
              type: 'FRAME' as const,
            })),
            ...after.map((a) => ({
              ...a,
              type: 'OPEN' as const,
            })),
          ],
        };
      }
      const { frames, ...rest } = await api.getEvaluator(model, dialect);
      return {
        ...rest,
        questions: frames.map((frame) => ({
          ...frame,
          type: 'FRAME' as const,
        })),
      };
    },
  });

export const usePostEvaluator = () =>
  useMutation({
    mutationFn: ({
      model,
      evaluatorId,
      data,
      token,
    }: {
      model: Evaluator;
      evaluatorId: string;
      data: api.SubmitEvaluatorData;
      token?: string;
    }) => api.submitEvaluator(model, evaluatorId, data, token),
  });

export const useConfirmEmail = () =>
  useMutation({
    mutationFn: ({
      evaluatorLinkId,
      data,
    }: {
      evaluatorLinkId: string;
      data: api.VerifyEmailData;
    }) => api.verifyEmail(evaluatorLinkId, data),
  });

export const useVerifyEvaluatorlinkId = () => {
  return useMutation({
    mutationFn: ({ evaluatorLinkId }: { evaluatorLinkId: string }) =>
      api.verifyEvaluatorlinkId(evaluatorLinkId),
  });
};
