import React, { useState } from 'react';
import { Box, Drawer, Typography } from '@mui/material';
import {
  ColourEnergyBox,
  H4,
  Span,
  SvgHelpWheel,
} from '@insights-ltd/design-library/components';
import { Trans, useTranslation } from 'react-i18next';
import { Colour, ColourEnergy, ColourEnergyProps } from 'types/constants';
import { colourMap } from 'variables';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';

const DrawerButton = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const colourEnergies = (
    Object.keys(colourMap) as ColourEnergy[]
  ).reduce<ColourEnergyProps>((prev, colour) => {
    const colourEnergy: Colour = colourMap[colour];

    const colourEnergyName = t(
      `ui.io.explore.evaluator.digital_profile.colour_energy_${colourEnergy}_name`,
    );

    const colourDescription = t(
      `ui.io.explore.evaluator.digital_profile.colour_energy_${colourEnergy}_description`,
    );

    return {
      ...prev,
      [colour]: { colourEnergyName, colourDescription },
    };
  }, {} as ColourEnergyProps);

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={toggleDrawer(false)}
      onClick={toggleDrawer(!open)}
      ModalProps={{
        keepMounted: true,
      }}
      sx={{
        '.MuiDrawer-paper': {
          borderBottomLeftRadius: 4,
          height: '0',
          minHeight: '363px',
          overflow: 'visible',
          padding: '12px 24px 12px 16px',
          position: 'absolute',
          top: 240,
          right: open
            ? 0
            : window.innerWidth - document.documentElement.clientWidth,
        },
      }}
    >
      <Box
        sx={{
          visibility: 'visible',
          width: '900px',
        }}
      >
        <Box
          data-testid="drawer-help"
          sx={(theme) => ({
            backgroundColor: theme.palette.grey[100],
            borderLeft: `2px solid ${theme.palette.blue.main}`,
            borderRight: `2px solid ${theme.palette.blue.main}`,
            borderTop: `2px solid ${theme.palette.blue.main}`,
            borderTopLeftRadius: 4,
            borderTopRightRadius: 4,
            color: theme.palette.blue.main,
            cursor: 'pointer',
            display: 'flex',
            fontWeight: theme.typography.fontWeightBold,
            height: 50,
            left: -25,
            padding: '10px 16px 10px 16px',
            position: 'absolute',
            top: 50,
            transform: 'translate(-50%, -50%) rotate(-90deg)',
            width: 100,
            '& span': {
              alignItems: 'center',
              marginLeft: '5px',
            },
            '& svg': {
              alignItems: 'center',
              height: '20px',
              transform: 'rotate(90deg)',
              width: '20px',
            },
          })}
        >
          <SvgHelpWheel />
          <span>
            {t(
              'ui.io.explore.evaluator.digital_profile.colour_energies_button',
            )}
          </span>
        </Box>
        <Box sx={{ maxWidth: '720px', paddingBottom: '0.5rem' }}>
          <H4 style={{ marginBottom: '0.5rem' }}>
            {t(
              'ui.io.explore.evaluator.digital_profile.colour_energies_header',
            )}
          </H4>
          <Typography color="textSecondary">
            <Trans
              i18nKey="ui.io.explore.evaluator.digital_profile.colour_energies_header_content"
              components={{
                bold: <Span variant="body-bold" color="textSecondary" />,
              }}
            />
          </Typography>
        </Box>
        <Box
          sx={(theme) => ({
            margin: `${theme.spacing(spacingSizeMap.XS)} 0 0 0`,
            overflow: 'hidden',
          })}
        >
          <ColourEnergyBox colourStrings={colourEnergies} />
        </Box>
      </Box>
    </Drawer>
  );
};

export default DrawerButton;
