import { DfcEvaluatorResponse } from 'api/evaluator';

export const dfcEvaluator: DfcEvaluatorResponse = {
  seed: 123,
  dialect: 'en',
  frames: [
    {
      propositions: [
        { key: '75', text: 'Courageous and independent' },
        { key: '84', text: 'Factual and conventional' },
        { key: '17', text: 'Loyal and accommodating' },
        { key: '62', text: 'Persuasive and animated' },
      ],
    },
    {
      propositions: [
        { key: '80', text: 'Organised and thoughtful' },
        { key: '11', text: 'Determined and dominant' },
        { key: '41', text: 'Even-tempered and amiable' },
        { key: '54', text: 'Eager and engaging' },
      ],
    },
  ],
  pronoun: 'SHE',
  before: [
    {
      key: 'choice-key-1',
      text: 'What is your relationship to {SubjectName}?',
      answers: [
        { key: 'choice-answer-1', text: 'choice 1' },
        { key: 'choice-answer-2', text: 'choice 2' },
        { key: 'choice-answer-3', text: 'choice 3' },
        { key: 'choice-answer-4', text: 'choice 4' },
      ],
    },
  ],
  after: [{ key: 'open-key-1', text: 'What is blah blah blah?' }],
};
