import React from 'react';
import { SvgComponentProps } from './types';

const SvgDiscoveryProfile = ({ title, ...props }: SvgComponentProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    width="271"
    height="229"
    viewBox="0 0 271 229"
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <path
      fill="#0E567B"
      d="M50.991 19c0-6.627 5.373-12 12-12h33.067a12 12 0 0 1 7.899 2.966l1.963 1.717a12 12 0 0 0 7.899 2.966h92.846c6.628 0 12 5.373 12 12v103.12c0 6.627-5.372 12-12 12H62.991c-6.627 0-12-5.373-12-12V19Z"
    />
    <path
      fill="#1673A3"
      d="M40.91 28.583c0-6.627 5.373-12 12-12h38.994a12 12 0 0 1 7.899 2.966l3.009 2.631a12 12 0 0 0 7.899 2.967h105.921c6.628 0 12 5.372 12 12v118.318c0 6.628-5.372 12-12 12H52.91c-6.627 0-12-5.372-12-12V28.583Z"
    />
    <g filter="url(#discovery-profile_svg__a)">
      <path
        fill="#fff"
        d="M30 38.125c0-6.627 5.373-12 12-12h45.612a12 12 0 0 1 7.9 2.966l4.177 3.653a12 12 0 0 0 7.898 2.966h120.521c6.627 0 12 5.372 12 12V183c0 6.627-5.373 12-12 12H42c-6.627 0-12-5.373-12-12V38.125Z"
      />
    </g>
    <rect
      width={58.465}
      height={5.933}
      x={43.258}
      y={48.947}
      fill="#E7E7E7"
      rx={2.967}
    />
    <rect
      width={84.5}
      height={5.933}
      x={43.252}
      y={68.117}
      fill="#E7E7E7"
      rx={2.967}
    />
    <rect
      width={58.465}
      height={5.933}
      x={43.252}
      y={58.525}
      fill="#E7E7E7"
      rx={2.967}
    />
    <rect
      width={84.5}
      height={5.933}
      x={43.252}
      y={77.7}
      fill="#E7E7E7"
      rx={2.967}
    />
    <path
      fill="#BA3135"
      d="M212.702 82.038a28.516 28.516 0 0 1-5.452 16.768 28.545 28.545 0 0 1-14.274 10.362l-8.821-27.13h28.547Z"
    />
    <path
      fill="#FFC85F"
      d="M193.335 109.049a28.564 28.564 0 0 1-21.803-1.425l12.623-25.586 9.18 27.011Z"
    />
    <path
      fill="#8AA52D"
      d="M172.723 108.178a28.545 28.545 0 0 1-16.45-20.017 28.505 28.505 0 0 1 6.536-25.064l21.346 18.941-11.432 26.14Z"
    />
    <path
      fill="#0073B8"
      d="M162.807 63.1a28.566 28.566 0 0 1 31.715-7.64 28.547 28.547 0 0 1 13.339 10.685 28.506 28.506 0 0 1 4.837 16.386l-28.543-.493L162.807 63.1Z"
    />
    <ellipse cx={165.719} cy={117.867} fill="#0073B8" rx={1.827} ry={1.826} />
    <ellipse cx={165.657} cy={124.716} fill="#BA3135" rx={1.827} ry={1.826} />
    <ellipse cx={190.371} cy={117.864} fill="#8AA52D" rx={1.827} ry={1.826} />
    <ellipse cx={190.371} cy={124.716} fill="#FFC85F" rx={1.827} ry={1.826} />
    <rect
      width={14.616}
      height={2.739}
      x={168.365}
      y={116.494}
      fill="#E6E6E6"
      rx={1.369}
    />
    <rect
      width={14.616}
      height={2.739}
      x={168.365}
      y={123.346}
      fill="#E6E6E6"
      rx={1.369}
    />
    <rect
      width={14.616}
      height={2.739}
      x={193.089}
      y={116.494}
      fill="#E6E6E6"
      rx={1.369}
    />
    <rect
      width={14.616}
      height={2.739}
      x={193.089}
      y={123.346}
      fill="#E6E6E6"
      rx={1.369}
    />
    <path
      fill="#E6E6E6"
      d="M43.258 104.973a4 4 0 0 1 4-4h6.616a4 4 0 0 1 4 4v68.571H43.258v-68.571Z"
    />
    <path
      fill="#0073B8"
      d="M43.252 130.533a4 4 0 0 1 4-4h6.616a4 4 0 0 1 4 4v43.012H43.252v-43.012Z"
    />
    <path
      fill="#E6E6E6"
      d="M89.834 104.973a4 4 0 0 1 4-4h6.616a4 4 0 0 1 4 4v68.57H89.834v-68.57Z"
    />
    <path
      fill="#BA3135"
      d="M89.834 148.33a4 4 0 0 1 4-4h6.616a4 4 0 0 1 4 4v25.211H89.834V148.33Z"
    />
    <path
      fill="#E6E6E6"
      d="M66.505 104.973a4 4 0 0 1 4-4h6.616a4 4 0 0 1 4 4v68.57H66.505v-68.57Z"
    />
    <path
      fill="#8AA52D"
      d="M66.505 123.233a4 4 0 0 1 4-4h6.616a4 4 0 0 1 4 4v50.314H66.505v-50.314Z"
    />
    <path
      fill="#E6E6E6"
      d="M113.177 104.973a4 4 0 0 1 4-4h6.616a4 4 0 0 1 4 4v68.57h-14.616v-68.57Z"
    />
    <path
      fill="#FFC85F"
      d="M113.177 134.642a4 4 0 0 1 4-4h6.616a4 4 0 0 1 4 4v38.904h-14.616v-38.904Z"
    />
    <g filter="url(#discovery-profile_svg__b)">
      <ellipse
        cx={236.863}
        cy={41.604}
        fill="#DE0072"
        rx={19.137}
        ry={19.123}
      />
    </g>
    <path
      fill="#fff"
      fillRule="evenodd"
      d="m246.731 35.942-2.366-2.404a.42.42 0 0 0-.6 0l-9.993 10.13a.42.42 0 0 1-.6 0l-2.366-2.404a.42.42 0 0 0-.6 0l-2.366 2.404a.432.432 0 0 0 0 .607l5.332 5.402a.423.423 0 0 0 .6 0l12.959-13.134a.432.432 0 0 0 0-.6Z"
      clipRule="evenodd"
    />
    <defs>
      <filter
        id="discovery-profile_svg__a"
        width={270.108}
        height={228.875}
        x={0}
        y={0.125}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={4} />
        <feGaussianBlur stdDeviation={15} />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
      <filter
        id="discovery-profile_svg__b"
        width={58.273}
        height={58.245}
        x={205.727}
        y={15.481}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dx={-2} dy={3} />
        <feGaussianBlur stdDeviation={5} />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
    </defs>
  </svg>
);

export default SvgDiscoveryProfile;
