import {
  Colour,
  ColourEnergy,
  ColourMapProp,
  DatadogRumConfigs,
  IndustrySectors,
  PageTitle,
} from 'types/constants';
import { Evaluator } from 'types/evaluator';

export const PROPOSITION_RANKS = [
  'L_SHORT',
  '1',
  '2',
  '3',
  '4',
  '5',
  'M_SHORT',
] as const;

type PropositionRankType = (typeof PROPOSITION_RANKS)[number];

export const PROPOSITION_RANK_ARIA_LABELS: Record<PropositionRankType, string> =
  {
    L_SHORT: 'ui.io.evaluator.proposition.heading_l',
    '1': '1',
    '2': '2',
    '3': '3',
    '4': '4',
    '5': '5',
    M_SHORT: 'ui.io.evaluator.proposition.heading_m',
  };

export const COUNTRIES: string[] = [
  'ui.io.demographics.country.rather_not_say',
  'ui.common.country.albania',
  'ui.common.country.algeria',
  'ui.common.country.argentina',
  'ui.common.country.australia',
  'ui.common.country.austria',
  'ui.common.country.azerbaijan',
  'ui.common.country.bahrain',
  'ui.common.country.belarus',
  'ui.common.country.belgium',
  'ui.common.country.belize',
  'ui.common.country.bolivia',
  'ui.common.country.bosnia-and-herzegovina',
  'ui.common.country.brazil',
  'ui.common.country.brunei-darussalam',
  'ui.common.country.bulgaria',
  'ui.common.country.canada',
  'ui.common.country.chile',
  'ui.common.country.china',
  'ui.common.country.colombia',
  'ui.common.country.costa-rica',
  'ui.common.country.croatia',
  'ui.common.country.czech-republic',
  'ui.common.country.denmark',
  'ui.common.country.dominican-republic',
  'ui.common.country.ecuador',
  'ui.common.country.egypt',
  'ui.common.country.el-salvador',
  'ui.common.country.estonia',
  'ui.common.country.faroe-islands',
  'ui.common.country.finland',
  'ui.common.country.france',
  'ui.common.country.germany',
  'ui.common.country.greece',
  'ui.common.country.greenland',
  'ui.common.country.guatemala',
  'ui.common.country.honduras',
  'ui.common.country.hong-kong',
  'ui.common.country.hungary',
  'ui.common.country.iceland',
  'ui.common.country.india',
  'ui.common.country.indonesia',
  'ui.common.country.iran-islamic-republic-of',
  'ui.common.country.iraq',
  'ui.common.country.ireland',
  'ui.common.country.israel',
  'ui.common.country.italy',
  'ui.common.country.jamaica',
  'ui.common.country.japan',
  'ui.common.country.jordan',
  'ui.common.country.kazakhstan',
  'ui.common.country.kenya',
  'ui.common.country.korea-republic-of',
  'ui.common.country.kuwait',
  'ui.common.country.kyrgyzstan',
  'ui.common.country.latvia',
  'ui.common.country.lebanon',
  'ui.common.country.libyan-arab-jamahiriya',
  'ui.common.country.liechtenstein',
  'ui.common.country.lithuania',
  'ui.common.country.luxembourg',
  'ui.common.country.macao',
  'ui.common.country.macedonia',
  'ui.common.country.malaysia',
  'ui.common.country.malta',
  'ui.common.country.mexico',
  'ui.common.country.monaco',
  'ui.common.country.mongolia',
  'ui.common.country.morocco',
  'ui.common.country.netherlands',
  'ui.common.country.new-zealand',
  'ui.common.country.nicaragua',
  'ui.common.country.nigeria',
  'ui.common.country.norway',
  'ui.common.country.oman',
  'ui.common.country.pakistan',
  'ui.common.country.panama',
  'ui.common.country.paraguay',
  'ui.common.country.peru',
  'ui.common.country.philippines',
  'ui.common.country.poland',
  'ui.common.country.portugal',
  'ui.common.country.puerto-rico',
  'ui.common.country.qatar',
  'ui.common.country.romania',
  'ui.common.country.russian-federa.',
  'ui.common.country.rwanda',
  'ui.common.country.saudi-arabia',
  'ui.common.country.senegal',
  'ui.common.country.serbia-and-montenegro',
  'ui.common.country.singapore',
  'ui.common.country.slovakia',
  'ui.common.country.slovenia',
  'ui.common.country.south-africa',
  'ui.common.country.spain',
  'ui.common.country.sweden',
  'ui.common.country.switzerland',
  'ui.common.country.syrian-arab-republic',
  'ui.common.country.taiwan',
  'ui.common.country.tajikistan',
  'ui.common.country.thailand',
  'ui.common.country.trinidad-and-tobago',
  'ui.common.country.tunisia',
  'ui.common.country.turkey',
  'ui.common.country.turkmenistan',
  'ui.common.country.ukraine',
  'ui.common.country.united-arab-emirates',
  'ui.common.country.united-kingdom',
  'ui.common.country.united-states',
  'ui.common.country.uruguay',
  'ui.common.country.uzbekistan',
  'ui.common.country.venezuela',
  'ui.common.country.vietnam',
  'ui.common.country.yemen',
  'ui.common.country.zimbabwe',
  'ui.common.country.other',
];

export const INDUSTRY_SECTORS: IndustrySectors = [
  'ui.io.demographics.job.sector.rather_not_say',
  'ui.io.demographics.job.sector.automobiles_and_components.name',
  'ui.io.demographics.job.sector.banks.name',
  'ui.io.demographics.job.sector.capital_goods.name',
  'ui.io.demographics.job.sector.consumer_and_professional_services.name',
  'ui.io.demographics.job.sector.consumer_durable_and_apparel.name',
  'ui.io.demographics.job.sector.consumer_services.name',
  'ui.io.demographics.job.sector.diversified_financials.name',
  'ui.io.demographics.job.sector.energy.name',
  'ui.io.demographics.job.sector.food_and_staples_retailing.name',
  'ui.io.demographics.job.sector.food_beverage_tobacco.name',
  'ui.io.demographics.job.sector.health_care.name',
  'ui.io.demographics.job.sector.household_and_personal_products.name',
  'ui.io.demographics.job.sector.insurance.name',
  'ui.io.demographics.job.sector.materials.name',
  'ui.io.demographics.job.sector.media.name',
  'ui.io.demographics.job.sector.pharma_biotech_and_life_sciences.name',
  'ui.io.demographics.job.sector.real_estate_and_investment_trusts.name',
  'ui.io.demographics.job.sector.retailing.name',
  'ui.io.demographics.job.sector.semiconductors_and_equip.name',
  'ui.io.demographics.job.sector.software_and_IT_services.name',
  'ui.io.demographics.job.sector.technology_hardware_and_equipment.name',
  'ui.io.demographics.job.sector.telecommunication_services.name',
  'ui.io.demographics.job.sector.transportation.name',
  'ui.io.demographics.job.sector.utilities.name',
  'ui.io.demographics.job.sector.other.name',
];

export const DATADOG_RUM_CONFIGS: DatadogRumConfigs[] = [
  {
    hostname: 'test.experience.insights.com',
    applicationId: 'd9edb65b-af8e-459e-8dd9-8d6434b00f4a',
    clientToken: 'pubafe2d24f6e87860fc91b4f53a43f4185',
  },
  {
    hostname: 'demo.experience.insights.com',
    applicationId: '7b510961-7b5f-427d-a205-32664c690aef',
    clientToken: 'pub2dd86c7a4eabdaadea2ed3d196a4107f',
  },
  {
    hostname: 'experience.insights.com',
    applicationId: 'ccc9f3d3-4557-46b7-8bb4-5f74fe4ae54b',
    clientToken: 'pub55c6d8b91bc578d1d215dad0301403b3',
  },
];

export const VALID_EMAIL_REGEX = /^[!-~]+@\S+\.\S+$/;

export const colourMap: ColourMapProp = {
  coolBlue: 'blue',
  earthGreen: 'green',
  sunshineYellow: 'yellow',
  fieryRed: 'red',
};

export const colourMapInverted: Record<Colour, ColourEnergy> = {
  blue: 'coolBlue',
  green: 'earthGreen',
  yellow: 'sunshineYellow',
  red: 'fieryRed',
};

export const EVALUATOR_TITLE_KEYS: Record<Evaluator, string> = {
  DDDRS: 'ui.io.discovery.evaluator_title',
  DISCOVERY: 'ui.io.discovery.evaluator_title',
  DFC: 'ui.io.dfc.evaluator_title',
  EXPLORE: 'ui.io.discovery.evaluator_title',
};

export const PAGE_TITLE_KEYS: Record<PageTitle, string> = {
  WELCOME: 'ui.io.onboarding.welcome_title',
  YOUR_RELATIONSHIP: 'ui.io.evaluator.dfc.choice_question.title',
  QUESTION: 'ui.io.evaluator.progress_text',
  THANK_YOU: 'ui.io.evaluator.complete.page_title',
  INSTRUCTIONS: 'ui.io.evaluator.instructions.info_title',
  ABOUT_YOU: 'ui.io.about_you.page_title',
  ERROR_ROUTE: 'ui.io.invite.error.generic_error',
  ERROR_TOKEN: 'ui.io.error.invite_expired.title',
  CONFIRM_EMAIL: 'ui.io.confirm_email.email_form.heading',
  DIGITAL_PROFILE: 'ui.io.explore.evaluator.digital_profile.title',
};
