import { Box, Container } from '@mui/material';
import ExploreHeader from 'components/Header/Explore';
import ExploreFooter from 'components/Footer/Explore';
import React from 'react';
import useAboveMobile from 'components/hooks/useAboveMobile';
import { Text } from '@insights-ltd/design-library/components';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';
import Hero, { HeroProps } from 'components/Hero/Hero';

type ProfileLayoutProps = {
  title?: string;
  hero?: HeroProps;
  children?: React.ReactNode;
};

const ProfileLayout: React.FunctionComponent<ProfileLayoutProps> = ({
  title,
  hero,
  children,
}) => {
  const aboveMobile = useAboveMobile();

  return (
    <Box>
      <ExploreHeader title={aboveMobile ? title : undefined} />
      {!aboveMobile && (
        <Container
          disableGutters
          sx={(theme) => ({
            margin: `${theme.spacing(spacingSizeMap.S)} 0`,
            paddingLeft: `${theme.spacing(spacingSizeMap.S)} !important`,
            paddingRight: `${theme.spacing(spacingSizeMap.S)} !important`,
            [theme.breakpoints.up('lg')]: {
              paddingLeft: '0 !important',
              paddingRight: '0 !important',
            },
          })}
          maxWidth="lg"
          translate="no"
        >
          <Text variant="body-bold">{title}</Text>
        </Container>
      )}
      {hero && (
        <Hero
          image={hero.image}
          title={hero.title}
          buttonText={hero.buttonText}
          buttonIcon={hero.buttonIcon}
          buttonOnClick={hero.buttonOnClick}
        />
      )}
      <Container
        maxWidth="lg"
        disableGutters={!aboveMobile}
        sx={(theme) => ({
          minHeight: aboveMobile
            ? 'calc(100vh - 224px)'
            : 'calc(100vh - 192px)',
          marginTop: aboveMobile ? theme.spacing(spacingSizeMap.L) : null,
          marginBottom: 'auto',
          paddingLeft: `${theme.spacing(spacingSizeMap.S)} !important`,
          paddingRight: `${theme.spacing(spacingSizeMap.S)} !important`,
          [theme.breakpoints.up(1172)]: {
            paddingLeft: '0 !important',
            paddingRight: '0 !important',
          },
        })}
      >
        <Box
          sx={{
            alignItems: 'left',
            display: 'flex',
            flexDirection: 'column',
            marginLeft: 'auto',
            marginRight: 'auto',
            maxWidth: '1440px',
            width: '100%',
          }}
        >
          {children}
        </Box>
      </Container>
      <ExploreFooter />
    </Box>
  );
};

export default ProfileLayout;
