import React, { useState } from 'react';
import EmailForm from './EmailForm';
import SentForm from './SentForm';

const STAGES = {
  CONFIRM_EMAIL: 'CONFIRM_EMAIL',
  CONFIRMATION_SENT: 'CONFIRMATION_SENT',
} as const;

type States = keyof typeof STAGES;

const ConfirmEmailForm = ({ evaluatorLinkId }: { evaluatorLinkId: string }) => {
  const [stage, setStage] = useState<States>(STAGES.CONFIRM_EMAIL);
  const [submittedEmailAddress, setsubmittedEmailAddress] = useState('');

  const showEmailForm = stage === STAGES.CONFIRM_EMAIL;
  const onComplete = (emailAddress: string) => {
    setsubmittedEmailAddress(emailAddress);
    setStage(STAGES.CONFIRMATION_SENT);
  };

  return showEmailForm ? (
    <EmailForm
      defaultEmailAddress={submittedEmailAddress}
      onComplete={onComplete}
      evaluatorLinkId={evaluatorLinkId}
    />
  ) : (
    <SentForm emailAddress={submittedEmailAddress} />
  );
};

export default ConfirmEmailForm;
