import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { useVerifyEvaluatorlinkId } from 'api/evaluator.hooks';
import ColourChartSplitLayout from 'components/layout/ColourChartSplitLayout';
import ConfirmEmailForm from 'components/ConfirmEmailForm/ConfirmEmailForm';
import { FullScreenError, FullScreenSpinner } from 'components/FullScreen';
import useApplyQueryDialect from 'components/hooks/useApplyQueryDialect';
import { EVALUATOR_TITLE_KEYS, PAGE_TITLE_KEYS } from 'variables';

const ConfirmEmail = () => {
  useApplyQueryDialect();
  const { t } = useTranslation();

  const { evaluatorLinkId } = useParams<{ evaluatorLinkId: string }>();
  const { status, mutate: verifyLinkId } = useVerifyEvaluatorlinkId();

  useEffect(() => {
    if (evaluatorLinkId) {
      verifyLinkId({ evaluatorLinkId });
    }
  }, [evaluatorLinkId, verifyLinkId]);

  if (status === 'error') {
    return (
      <FullScreenError
        message={t('ui.io.confirm_email.error.invalid_evaluator_link')}
      />
    );
  }
  if (status === 'pending' || status === 'idle') {
    return <FullScreenSpinner />;
  }

  return (
    <ColourChartSplitLayout>
      <Helmet
        title={`${t(PAGE_TITLE_KEYS.CONFIRM_EMAIL)} - ${t(
          EVALUATOR_TITLE_KEYS.DDDRS,
        )}`}
      />
      <ConfirmEmailForm evaluatorLinkId={evaluatorLinkId!} />
    </ColourChartSplitLayout>
  );
};

export default ConfirmEmail;
