import { unauthenticatedFetch } from './fetch';

export type GetTokenDetailsResponse =
  | {
      model: 'DISCOVERY' | 'DDDRS';
      evaluatorId: string;
    }
  | {
      model: 'DFC';
      evaluatorId: string;
      learner: {
        fullName: string;
      };
    };

export const getTokenDetails = async (
  token: string,
  dialect?: string,
): Promise<GetTokenDetailsResponse | false> => {
  const query = dialect ? `?dialect=${dialect}` : '';
  const response = await unauthenticatedFetch(
    `/api/v1/invite/${token}${query}`,
  );
  if (!response.ok) {
    return false;
  }
  return response.json();
};
