import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import {
  ONE_LEAST,
  ONE_MOST,
  TWO_UNIQUE_BETWEEN,
  Errors,
} from './validationUtils';

const ERROR_18N_MAP = {
  [ONE_MOST]: 'ui.io.evaluator.validation.instruction.error.one_most',
  [ONE_LEAST]: 'ui.io.evaluator.validation.instruction.error.one_least',
  [TWO_UNIQUE_BETWEEN]:
    'ui.io.evaluator.validation.instruction.error.two_between',
};

type ErrorType = typeof ONE_MOST | typeof ONE_LEAST | typeof TWO_UNIQUE_BETWEEN;

const searchTypes: ErrorType[] = [ONE_MOST, ONE_LEAST, TWO_UNIQUE_BETWEEN];

const ValidationInstruction = ({
  errors,
  subjectFullName,
}: {
  errors: Errors;
  subjectFullName?: string;
}) => {
  const { t } = useTranslation();
  const errorTypes = searchTypes.filter((type) => Boolean(errors[type]));
  const firstErrorType: ErrorType = errorTypes?.[0];

  const subjectName = !subjectFullName ? 'me' : subjectFullName;

  const instruction: string = !subjectFullName
    ? t('ui.io.evaluator.validation.instruction.default')
    : t('ui.io.evaluator.validation.instruction.dfc', { subjectName });

  return firstErrorType ? (
    <Typography color="error" sx={{ margin: '1rem 0' }}>
      {t(ERROR_18N_MAP[firstErrorType], { subjectName })}
    </Typography>
  ) : (
    <Typography color="textPrimary" sx={{ margin: '1rem 0' }}>
      {instruction}
    </Typography>
  );
};

export default ValidationInstruction;
