import { Container, Link, styled, Typography } from '@mui/material';
import Copyright from 'components/Copyright';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';
import React from 'react';
import { useTranslation } from 'react-i18next';
import privacyPolicyURL from 'utils/privacyPolicy';

const FooterWrapper = styled('footer')(({ theme }) => ({
  backgroundColor: theme.palette.blue.dark,
  color: theme.palette.grey[100],
  paddingTop: theme.spacing(spacingSizeMap.L),
  minHeight: '120px',
}));

const ExploreFooter = () => {
  const { t, i18n } = useTranslation();

  return (
    <FooterWrapper>
      <Container
        maxWidth="lg"
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Copyright color="inherit" />
        <Typography>
          <Link
            color="inherit"
            href={privacyPolicyURL(i18n.language)}
            target="_blank"
            rel="noopener"
          >
            {t('ui.io.footer.privacy_notice')}
          </Link>
        </Typography>
      </Container>
    </FooterWrapper>
  );
};

export default ExploreFooter;
