import { styled } from '@mui/material';

export const StyledPronounInfoWrapper = styled('div')({
  marginTop: '2rem',
  marginBottom: '3rem',

  '& > * + *': {
    marginTop: '0.5rem',
  },
});

export const StyledFieldWrapperEmulator = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  '& > div': {
    flexGrow: 1,
    marginTop: '1rem',
  },
});

export const StyledInputWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  marginTop: '1rem',
  marginBottom: '1rem',
});

export const StyledFormInputWrapper = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  marginTop: '1rem',
  marginBottom: '1rem',
});
