import { datadogRum } from '@datadog/browser-rum';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { worker } from 'mocks/browser';
import { DATADOG_RUM_CONFIGS } from 'variables';
import AppWithAuth from './App';
import 'normalize.css';
import './i18n';

if (import.meta.env.VITE_MOCK_API) {
  worker.start().then();
}

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <AppWithAuth />
  </React.StrictMode>,
);

if (import.meta.env.PROD) {
  const hostConfig = DATADOG_RUM_CONFIGS.find(
    (config) => config.hostname === window.location.host,
  );

  if (hostConfig) {
    datadogRum.init({
      applicationId: hostConfig.applicationId,
      clientToken: hostConfig.clientToken,
      env: hostConfig.hostname,
      site: 'datadoghq.eu',
      service: 'learner-webapp',
      version: import.meta.env.VITE_DATADOG_VERSION,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 20,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask',
      allowedTracingUrls: [`https://${hostConfig.hostname}`], // origins called by your application
      // See: https://docs.datadoghq.com/real_user_monitoring/guide/browser-sdk-upgrade/#rum-errors
      beforeSend: (event) => {
        if (
          event.type === 'resource' &&
          (event.resource.status_code ?? 0) >= 500
        ) {
          datadogRum.addError(
            `${event.resource.method} ${event.resource.url} ${event.resource.status_code}`,
          );
        }
        return true;
      },
    });

    datadogRum.startSessionReplayRecording();
  }
}
