export type RequestErrorBody = {
  errors: { code: string }[];
  message: string;
  status: number;
  ts: string;
};

export class RequestError extends Error {
  readonly errorCodes: string[];

  status: number;

  getRequestErrorKeys = (
    defaultKey: string,
    httpCodeKeys: Record<number, string> = {},
    errorCodeKeys: Record<string, string> = {},
  ) => {
    const genericError = httpCodeKeys[this.status]
      ? [httpCodeKeys[this.status]]
      : null;
    const specificErrorStrings = this.errorCodes.reduce(
      (accumulator: string[], currCode: string) => {
        if (errorCodeKeys[currCode]) {
          accumulator.push(errorCodeKeys[currCode]);
        }
        return accumulator;
      },
      [],
    );
    if (specificErrorStrings && specificErrorStrings.length) {
      return specificErrorStrings;
    }
    if (genericError) {
      return genericError;
    }
    return [defaultKey];
  };

  constructor(message: string, responseBody: RequestErrorBody) {
    // 'Error' breaks prototype chain here in ES5
    super(message);
    // restore prototype chain
    Object.setPrototypeOf(this, RequestError.prototype);

    this.status = responseBody.status;
    this.errorCodes = responseBody.errors.map((codeObj) => codeObj.code);
  }
}
