import React from 'react';
import {
  ColourProfileIcon,
  SvgCheck1Regular,
} from '@insights-ltd/design-library/components';
import { getTheme, spacingSizeMap } from '@insights-ltd/design-library/themes';
import { Box, Grid, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Colour, ColourScoreWithPreference } from 'types/constants';
import { colourMapInverted } from 'variables';

const localTheme = getTheme();

type SectionColourOrderProps = {
  profileConscious: ColourScoreWithPreference;
};

const StyledCheckIcon = styled(SvgCheck1Regular, {
  shouldForwardProp: (prop) => prop !== 'colour',
})<{ colour: 'white' | 'black' }>(({ theme, colour }) => ({
  padding: `0 ${theme.spacing(spacingSizeMap.XS)} 0 0`,
  marginTop: theme.spacing(0.5 * spacingSizeMap.XS),
  stroke:
    colour === 'white' ? theme.palette.grey[100] : theme.palette.grey[700],
  strokeWidth: '3',
  height: '12px',
}));

const SectionColourOrder = ({ profileConscious }: SectionColourOrderProps) => {
  const { t } = useTranslation();

  const colourOrder: Colour[] = (
    Object.keys(profileConscious).filter(
      (key) => key !== 'preferred',
    ) as Colour[]
  ).sort((a, b) => profileConscious[b] - profileConscious[a]);

  const colourEnergies = colourOrder.map((x, index) => {
    return (
      <Grid key={`energy-${x}`} item xs={12} sm={12} md={6}>
        <Box
          sx={(theme) => ({
            display: 'flex',
            paddingBottom: theme.spacing(spacingSizeMap.XS),
          })}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'baseline',
              paddingTop: '4px',
              height: '58px',
              width: '52px',
            }}
          >
            <ColourProfileIcon
              colourEnergy={colourMapInverted[x]}
              whiteBackgroundBorder
              iconSize={40}
            />
          </Box>
          <Box>
            <Box
              sx={(theme) => ({
                fontSize: '18px',
                fontWeight: theme.typography.fontWeightBold,
                lineHeight: '30px',
              })}
            >
              {`${index + 1}. ${t(
                `ui.io.explore.evaluator.digital_profile.colour_order.${x}_title`,
              )}`}
            </Box>
            <Box>
              {t(
                `ui.io.explore.evaluator.digital_profile.colour_order.${x}_subtitle`,
              )}
            </Box>
          </Box>
        </Box>
        <Grid
          container
          sx={(theme) => ({
            backgroundColor:
              localTheme.fourColourProfileColours[colourMapInverted[x]],
            borderRadius: '4px',
            color:
              x === 'yellow'
                ? theme.palette.grey[700]
                : theme.palette.grey[100],
            fontWeight: theme.typography.fontWeightRegular,
            padding: `${theme.spacing(spacingSizeMap.M)} ${theme.spacing(
              spacingSizeMap.L,
            )}`,
          })}
        >
          <Grid
            key={`attribute1-${x}`}
            item
            xs={12}
            sm={12}
            md={6}
            sx={(theme) => ({
              paddingBottom: theme.spacing(spacingSizeMap.XS),
              display: 'flex',
            })}
          >
            <StyledCheckIcon colour={x === 'yellow' ? 'black' : 'white'} />
            <Box sx={{ wordWrap: 'break-word' }}>
              {t(
                `ui.io.explore.evaluator.digital_profile.colour_order.${x}_attribute_1`,
              )}
            </Box>
          </Grid>
          <Grid
            key={`attribute2-${x}`}
            item
            xs={12}
            sm={12}
            md={6}
            sx={(theme) => ({
              paddingBottom: theme.spacing(spacingSizeMap.XS),
              display: 'flex',
            })}
          >
            <StyledCheckIcon colour={x === 'yellow' ? 'black' : 'white'} />
            <Box sx={{ wordWrap: 'break-word' }}>
              {t(
                `ui.io.explore.evaluator.digital_profile.colour_order.${x}_attribute_2`,
              )}
            </Box>
          </Grid>
          <Grid
            key={`attribute3-${x}`}
            item
            xs={12}
            sm={12}
            md={6}
            sx={(theme) => ({
              paddingBottom: theme.spacing(spacingSizeMap.XS),
              display: 'flex',
            })}
          >
            <StyledCheckIcon colour={x === 'yellow' ? 'black' : 'white'} />
            <Box sx={{ wordWrap: 'break-word' }}>
              {t(
                `ui.io.explore.evaluator.digital_profile.colour_order.${x}_attribute_3`,
              )}
            </Box>
          </Grid>
          <Grid
            key={`attribute4-${x}`}
            item
            xs={12}
            sm={12}
            md={6}
            sx={(theme) => ({
              paddingBottom: theme.spacing(spacingSizeMap.XS),
              display: 'flex',
            })}
          >
            <StyledCheckIcon colour={x === 'yellow' ? 'black' : 'white'} />
            <Box sx={{ wordWrap: 'break-word' }}>
              {t(
                `ui.io.explore.evaluator.digital_profile.colour_order.${x}_attribute_4`,
              )}
            </Box>
          </Grid>
        </Grid>
      </Grid>
    );
  });

  return (
    <>
      <h2>
        {t(
          'ui.io.explore.evaluator.digital_profile.colour_order.section_heading',
        )}
      </h2>
      <Grid
        container
        columnSpacing={{ xs: 3, sm: 3 }}
        rowSpacing={{ xs: 3, sm: 3 }}
      >
        {colourEnergies}
      </Grid>
    </>
  );
};

export default SectionColourOrder;
