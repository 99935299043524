import React from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const Copyright = ({
  color = 'textSecondary',
}: {
  color?: 'textSecondary' | 'inherit';
}) => {
  const { t } = useTranslation();
  return (
    <Typography color={color}>
      &copy;&nbsp;
      {t('ui.io.footer.copyright_message', {
        Year: new Date().getUTCFullYear(),
      })}
    </Typography>
  );
};

export default Copyright;
