import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { P, TextAreaV2 } from '@insights-ltd/design-library/components';
import { Box, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import QuestionNavigation from './QuestionNavigation';

type FormDataType = { open: string };
type Props = {
  prompt: string;
  submitAnswer: (value: string) => void;
  answer?: string;
  subjectFullName: string;
  isFirstQuestion: boolean;
  isLastQuestion: boolean;
  onBack: () => void;
};

const MAX_LENGTH = 1000;

const DfcInfo = ({ subjectFullName }: { subjectFullName: string }) => {
  const { t } = useTranslation();
  return (
    <Box px={2} py={1} bgcolor="grey.200" my={2}>
      <P variant="body-bold">
        {t('ui.io.evaluator.dfc.subject-warning.answer', {
          subjectFullName,
        })}
      </P>
    </Box>
  );
};

const Open = ({
  prompt,
  submitAnswer,
  answer,
  subjectFullName,
  isFirstQuestion,
  isLastQuestion,
  onBack,
}: Props) => {
  const { handleSubmit, control } = useForm<FormDataType>({
    defaultValues: { open: answer || '' },
  });
  const { t } = useTranslation();

  const onSubmit = (data: FormDataType) => submitAnswer(data.open);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DfcInfo subjectFullName={subjectFullName} />
      <P variant="body-bold">{prompt}</P>
      <Controller
        name="open"
        control={control}
        rules={{ maxLength: MAX_LENGTH }}
        render={({ field: { ref, ...props } }) => (
          <TextAreaV2
            fullWidth
            maxLength={MAX_LENGTH}
            multiline
            variant="outlined"
            minRows={5}
            placeholder={t('ui.io.evaluator.dfc.open-question.placeholder')}
            characterLimitLabelText={t(
              'ui.io.evaluator.dfc.open-question.character_limit',
            )}
            {...props}
          />
        )}
      />
      <Divider />
      <QuestionNavigation
        isFirstQuestion={isFirstQuestion}
        isLastQuestion={isLastQuestion}
        onBack={onBack}
      />
    </form>
  );
};

export default Open;
