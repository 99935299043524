import React from 'react';
import { useTranslation } from 'react-i18next';
import { styled, Typography } from '@mui/material';
import MailSvg from 'components/Svgs/Mail';
import { Avatar } from '@insights-ltd/design-library/components';
import useAboveMobile from 'components/hooks/useAboveMobile';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';

const StyledSentContainer = styled('div')(({ theme }) => ({
  minHeight: '70vh',
  marginTop: theme.spacing(spacingSizeMap.XL),
  textAlign: 'center',
  paddingRight: theme.spacing(1.75 * spacingSizeMap.L),
  paddingLeft: theme.spacing(1.5 * spacingSizeMap.L),
  '@media screen and (max-width: 768px)': {
    paddingRight: theme.spacing(spacingSizeMap.XL),
    paddingLeft: theme.spacing(spacingSizeMap.XL),
  },
}));

const StyledIconWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginBottom: theme.spacing(spacingSizeMap.S),
  '> div': {
    width: '4rem',
    height: '4rem',
  },
}));

const StyledMailIcon = styled(MailSvg)(({ theme }) => ({
  width: '2.5rem',
  fill: theme.palette.pink.dark,
}));

const SentForm = ({ emailAddress }: { emailAddress: string }) => {
  const { t } = useTranslation();
  const aboveMobile = useAboveMobile();
  return (
    <StyledSentContainer>
      <StyledIconWrapper>
        <Avatar>
          <StyledMailIcon aria-hidden />
        </Avatar>
      </StyledIconWrapper>
      <Typography
        sx={(theme) => ({ marginBottom: theme.spacing(4) })}
        variant={aboveMobile ? 'h4' : 'h5'}
        gutterBottom
      >
        {t('ui.io.confirm_email.sent_form.heading')}
      </Typography>
      <Typography
        sx={(theme) => ({
          marginBottom: theme.spacing(4),
          lineHeight: '1.75rem',
        })}
        color="textSecondary"
        gutterBottom
      >
        {t('ui.io.confirm_email.sent_form.body', { emailAddress })}
      </Typography>
      <Typography
        sx={() => ({
          lineHeight: '1.75rem',
        })}
        color="textSecondary"
        gutterBottom
      >
        {t('ui.io.confirm_email.sent_form.check_spam')}
      </Typography>
    </StyledSentContainer>
  );
};

export default SentForm;
