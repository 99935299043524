import React from 'react';
import { CircularProgress, styled, Typography } from '@mui/material';

const StyledDiv = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: '1',
  alignItems: 'center',
  justifyContent: 'center',
  minWidth: '100%',
  minHeight: '100%',
});

const FullScreenSpinner = ({ message }: { message?: string }) => (
  <StyledDiv>
    <CircularProgress size={50} color="secondary" data-testid="spinner" />
    {message && (
      <Typography variant="body1" component="h2">
        {message}
      </Typography>
    )}
  </StyledDiv>
);

export default FullScreenSpinner;
